import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface AdmnField {
    name: string,
    category: string,
    options?: string[],
    isMandatory: boolean,
    isActive: boolean,
    order: number
}

interface FormProps {
    onNext: (data: any) => void,
    formData?: any,
    allFields: AdmnField[],
    studentID: string,
    schoolID: string,
    classID: string
}

const CommonFormOne: React.FC<FormProps> = ({ onNext, allFields, studentID, schoolID, classID, formData }) => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [admnFields, setAdmnFields] = useState < AdmnField[] > ([]);
    const [formDataOne, setFormDataOne] = useState < any > ({});

    const handleChange = (val: string, property: string) => {
        setFormDataOne({
            ...formDataOne, // Copy the old fields
            [property]: val // But override this one
          });
    }
    
    const handleNext = () => {

        for (let i = 0; i < admnFields.length ; i++) {
            if (admnFields[i].isActive && admnFields[i].isMandatory) {
                if (formDataOne[admnFields[i].name] === "") {
                    setMessage(`${admnFields[i].name} ${t("is_mandatory")}`);
                    setIserror(true);
                    return;
                }
            }
        }
        onNext(formDataOne);
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const admnUser = await api.get(`/admissionUsers/${authInfo.user._id}`);

                let childFields: AdmnField[] = [];
                let childData: any = {};

                for (let i = 0; i < allFields.length ; i++) {
                    if (allFields[i].category === "Child") {
                        childFields.push(allFields[i]);

                        if(formData[allFields[i].name] !== undefined)
                        {
                            childData[allFields[i].name] = formData[allFields[i].name];
                        }
                        else
                        {
                            if (admnUser.data.children.find((val: any) => val._id === studentID)!.childFields !== undefined && admnUser.data.children.find((val: any) => val._id === studentID)!.childFields.find((val: any) => val.name === allFields[i].name) !== undefined)
                            {
                                childData[allFields[i].name] = admnUser.data.children.find((val: any) => val._id === studentID)!.childFields.find((val: any) => val.name === allFields[i].name).value;
                            }
                            else
                            {
                                childData[allFields[i].name] = "";
                            }
                        }
                    }
                }
                setAdmnFields(childFields);
                setFormDataOne(childData);

            } catch (error: any) {
                setMessage(t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [allFields, authInfo, formData, studentID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/application/${schoolID}/${classID}`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("childs_details")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("childs_details")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonGrid className="ion-no-padding">

                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(admnFields.length > 0) && admnFields.map((admnField, i) => (
                                             (admnField.isActive) &&
                                             (
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}{admnField.isMandatory && "*"}</IonLabel>

                                                    {(admnField.options === undefined) && (
                                                    <IonInput type="text"
                                                        value={formDataOne[admnField.name]}
                                                        placeholder={`${t("childs")} ${admnField.name}`}
                                                        onIonInput={
                                                            (e) => handleChange(e.detail.value!, admnField.name)
                                                    }></IonInput>
                                                    )}

                                                    {(admnField.options !== undefined && admnField.options.length === 0) && (
                                                    <IonInput type="text"
                                                        value={formDataOne[admnField.name]}
                                                        placeholder={`${admnField.name}`}
                                                        onIonInput={
                                                            (e) => handleChange(e.detail.value!, admnField.name)
                                                    }></IonInput>
                                                    )}

                                                    {(admnField.options !== undefined && admnField.options.length > 0) && (
                                                         <IonSelect value={formDataOne[admnField.name]}
                                                            placeholder={`${t("childs")} ${admnField.name}`}
                                                            onIonChange={
                                                                (e) => handleChange(e.detail.value, admnField.name)
                                                            }
                                                            >
                                                           
                                                           { admnField.options.map((option, i) => {
                                                                return (<IonSelectOption key={i} value={option}>{option}</IonSelectOption>);
                                                            })
                                                        }
                                                         </IonSelect>
                                                    )}

                                                </IonItem>
                                             )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton onClick={() => history.push(`/app/application/${schoolID}/${classID}`)}
                                className="first-button"
                                fill="clear"
                                >&lt; {t("previous")}</IonButton>

                        </IonCol>
                        <IonCol className="ion-text-right">
                            <IonButton onClick={handleNext}
                                className="first-button"
                                fill="clear"
                                >{t("next")} &gt;</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CommonFormOne;
