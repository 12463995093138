import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonNote,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { addCircleOutline, 
    alertCircleOutline, 
    attach, 
    camera, 
    chevronForwardOutline, 
    imageOutline, 
    images, 
    informationCircleOutline, 
    star, 
    starOutline, 
    trash, 
    trashOutline, 
    videocam,
 } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { PhotoViewer, Image } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { Calendar, Day } from '@hassanmojab/react-modern-calendar-datepicker';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import FileOpener from "../../../components/FileOpener";
import useApiService from '../../../components/ApiService';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

interface Attach {
    caption: string,
    filename: string
}

interface FileUpload {
    caption: string,
    file: File,
    imageURL: string,
    ext: string
}

interface FileInfo {
    imageURL: string,
    ext: string
}

interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}

const DailyReports: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [selectedDay, setSelectedDay] = useState < Day > ();
    const [today, setToday] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);
    const [pop, setPop] = useState < boolean > (false);
    const [work, setWork] = useState < string > ("");
    const [files, setFiles] = useState < Attach[] > ([]);
    const [fileUploads, setFileUploads] = useState < FileUpload[] > ([]);
    const [caption, setCaption] = useState < string > ("");
    const [upload, setUpload] = useState < File[] > ([]);
    const [imageURL, setImageURL] = useState < FileInfo[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [reportID, setReportID] = useState < string > ("");
    const [response, setResponse] = useState < string > ("");
    const [rating, setRating] = useState < number > (0);
    const [mode, setMode] = useState < string > ("");

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const openImage = (url: string, location: string) => {

        let images: Image[] = [];
        let startFrom = 0;
        let j = -1;

        if (location === "web")
        {
            for (let i = 0; i < files.length; i++)
            {
                if(isFileImage(files[i].filename))
                {
                    j += 1;
                    images.push({url: `${process.env.REACT_APP_STATIC_S3}/reports/${files[i].filename}`, title: files[i].caption});
                    if (files[i].filename === url)
                    {
                        startFrom = j;
                    }
                }
            }
        }
        if (location === "local")
        {
            for (let i = 0; i < fileUploads.length; i++)
            {
                if((fileUploads[i].ext !== "mp4") && (fileUploads[i].ext !== "pdf"))
                {
                    j += 1;
                    images.push({url: fileUploads[i].imageURL, title: fileUploads[i].caption});
                    if (fileUploads[i].imageURL === url)
                    {
                        startFrom = j;
                    }
                }
                
            }
        }

        if (images.length > 1)
        {
            PhotoViewer.show({images, mode: isPlatform('mobile') ? 'slider' : 'gallery', startFrom});
        }
        if (images.length === 1)
        {
            PhotoViewer.show({images, mode: 'one'});
        }

    };

    const openFile = async (url: string, ext: string) => {
        const mimeType = getMimeType(ext);
        if (isPlatform('capacitor'))
        {
            try  {
                await FileOpener.open({filePath: url, contentType: mimeType});
            }
            catch {
                setMessage(t("file_error"));
                setIserror(true);
            }
            
        }
    };

    const formatDated = (value: Day) => {
        return value.day+"/"+value.month+"/"+value.year;
    };

    const formatDate = (value: string) => {
        const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
                        t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
                        ];

        const valueDate = new Date(value);
        return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    };

    const isFileImage= (fileName: string) => {
        // Extract the extension from the file name
        const extension = fileName.split('.').pop();
    
        // Check if the extension matches 'jpeg' or 'png'
        return extension === 'jpeg' || extension === 'png';
    };

    const isFileVideo= (fileName: string) => {
        const extension = fileName.split('.').pop();
        return extension === 'mp4' || extension === 'mov';
    };

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        if (ext === 'mp4')
            mime = 'video/mp4';
        if (ext === 'mov')
            mime = 'video/quicktime';
        if (ext === 'docx')
            mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (ext === 'xlsx')
            mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (ext === 'pptx')
            mime = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        if (ext === 'doc')
            mime = 'application/msword';
        if (ext === 'xls')
            mime = 'application/vnd.ms-excel';
        if (ext === 'ppt')
            mime = 'application/vnd.ms-powerpoint';
        return mime;
    };

    const getExtension = (ext: string) => {
        let mime = "";
        if (ext === 'image/jpeg')
            mime = 'jpeg';
        if (ext === 'image/png')
            mime = 'png';
        if (ext === 'application/pdf')
            mime = 'pdf';
        if (ext === 'video/mp4')
            mime = 'mp4';
        if (ext === 'video/quicktime')
            mime = 'mov';
        if (ext === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            mime = 'docx';
        if (ext === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            mime = 'xlsx';
        if (ext === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
            mime = 'pptx';
        if (ext === 'application/msword')
            mime = 'doc';
        if (ext === 'application/vnd.ms-excel')
            mime = 'xls';
        if (ext === 'application/vnd.ms-powerpoint')
            mime = 'ppt';
        return mime;
    };

    const downloadFile = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/reports/' + fileName;

        if (isPlatform('capacitor') && !showDownloading)
        {
            try {
                Filesystem.checkPermissions().then(async (res) => {
                    if (res.publicStorage !== 'granted') {
                        Filesystem.requestPermissions().then(async (ress) => {
                            if (ress.publicStorage === 'denied') {
                                setMessage(t("storage_denied"));
                                setIserror(true);
                            }
                            else {
                                setShowDownloading(true);
                                try {
                                    const savedFile = await Filesystem.downloadFile({
                                        path: fileName,
                                        url,
                                        directory: Directory.External,
                                    });
                                    
                                    if(savedFile.path) {
                                        await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                    } else {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                    
                                } catch(error: any) {
                                    setMessage(t("download_error"));  
                                    setIserror(true);
                                } finally {
                                    setShowDownloading(false);
                                }
                            }
                        });
                    }
                    else {
                        setShowDownloading(true);
                        try {
                            const savedFile = await Filesystem.downloadFile({
                                path: fileName,
                                url,
                                directory: Directory.External,
                            });
                            
                            if(savedFile.path) {
                                await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                            } else {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                            
                        } catch(error: any) {
                            setMessage(t("download_error"));  
                            setIserror(true);
                        } finally {
                            setShowDownloading(false);
                        }
                    }
                });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            } 
        }
        if (isPlatform('capacitor') && showDownloading)
        {
            setMessage(t("download_in_progress"));
            setIserror(true);
        }

        if (!isPlatform('capacitor'))
        {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noreferrer';
            link.download = 'download'; // Triggers download

            // Append the anchor to the body
            document.body.appendChild(link);

            // Programmatically click the link
            link.click();

            // Remove the anchor from the document
            document.body.removeChild(link);
        }

    };

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const takePhoto = async () => {
        const theUploads =  [...upload];
        const theURLs =  [...imageURL];
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions({ permissions: ['camera'] }).then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    source: CameraSource.Camera,
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: getMimeType(image.format),
                                    });
                                    theUploads.push(rawFile);
                                    const base64 = await fileToDataUri(rawFile);
                                    theURLs.push({imageURL: base64, ext: "jpeg"});
                                }
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            source: CameraSource.Camera,
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: getMimeType(image.format),
                            });
                            theUploads.push(rawFile);
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "jpeg"});
                        }
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

            setUpload(theUploads);
            setImageURL(theURLs);

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };


    const pickImages = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
            const result = await FilePicker.pickImages();
            const theUploads =  [...upload];
            const theURLs =  [...imageURL];

            for (let i=0; i < result.files.length; i++)
            {
                const file = result.files[i];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);

                        if (file.mimeType === 'image/jpeg')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "jpeg"});
                        }
                        if (file.mimeType === 'image/png')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "png"});
                        }
                    }
                    if ((file.mimeType === 'image/heic' || file.mimeType === 'image/heif'))
                    {
                        const jpegPath = await FilePicker.convertHeicToJpeg({path: file.path});
                        const webPath = convertFileSrc(jpegPath.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        const base64 = await fileToDataUri(rawFile);
                        theURLs.push({imageURL: base64, ext: "jpeg"});
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);

                        if (file.mimeType === 'image/jpeg')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "jpeg"});
                        }
                        if (file.mimeType === 'image/png')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "png"});
                        }
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(theURLs);
        } 
        catch (error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }

    };

    const pickFile = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {

            const result = await FilePicker.pickFiles({limit: 1});
            const theUploads =  [...upload];
            const theURLs =  [...imageURL];

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: file.path, ext: getExtension(file.mimeType)});
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: "", ext: getExtension(file.mimeType)});
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(theURLs);
        } 
        catch(error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }
    };

    const pickVideo = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
           const result = await FilePicker.pickVideos({limit: 1});
           const theUploads =  [...upload];
           const theURLs =  [...imageURL];

           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: file.path, ext: getExtension(file.mimeType)});
                    }
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: "", ext: getExtension(file.mimeType)});
                    }
               }
           }
           setUpload(theUploads);
           setImageURL(theURLs);
       } 
       catch(error: any) {
           setMessage(t("no_files_selected"));  
           setIserror(true);
       } finally {
            setShowLoading(false);
        }
   };

    const checkPast = (choosen : Day, aaj: Day) => {
        const dateString = choosen.month+"/"+choosen.day+"/"+choosen.year;
        const dateObject = new Date(dateString);
        const aajString = aaj.month+"/"+aaj.day+"/"+aaj.year;
        const aajObject = new Date(aajString);

        if(dateObject < aajObject) return true;
        else return false;
    }

    // Handle IonDate Popover Change (Displayed Month)
    const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string")
        {
            setSelectedDate(theDate);
            const onDate = new Date(theDate);

            const minDate = {
                day: 1,
                month: onDate.getMonth()+1,
                year: onDate.getFullYear()
                
            }
            setMinDay(minDate);
            setSelectedDay(minDate);

            const maxDate = {
                day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1),
                month: onDate.getMonth()+1,
                year: onDate.getFullYear()
            }
            setMaxDay(maxDate);

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const events = await api.get(`/dailyReports/getOneByMonth/${authInfo.user._id}/${theDate}`);

                    let result: Array < Attend > = [];
                    for (let i = 0; i < events.data.length ; i++) {
                        let dated: Date = new Date(events.data[i].calendarDate);
                        let dateA: number = dated.getDate();

                        let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                        result.push(calObj);
                
                    }
                    setAttend(result);

                    const res = await api.post('/dailyReports/getOneByDay', {'employeeID': authInfo.user._id, 'day': minDate});
                    if (res.data)
                    {
                        setReportID(res.data._id);
                        setWork(res.data.work);
                        setFiles(res.data.files);
                        setResponse(res.data.response);
                        setRating(res.data.rating);
                    }
                    else
                    {
                        setReportID("");
                        setWork("");
                        setFiles([]);
                        setResponse("");
                        setRating(0);
                    }
                
                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

    // Handle Day Change on Calendar
    const handleDate = async (dated : Day) => {

        setSelectedDay(dated);

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const res = await api.post('/dailyReports/getOneByDay', {'employeeID': authInfo.user._id, 'day': dated});
                if (res.data)
                {
                    setReportID(res.data._id);
                    setWork(res.data.work);
                    setFiles(res.data.files);
                    setResponse(res.data.response);
                    setRating(res.data.rating);
                    setMode(res.data.mode);
                }
                else
                {
                    setReportID("");
                    setWork("");
                    setFiles([]);
                    setResponse("");
                    setRating(0);
                    setMode("");
                }


            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();     
    }

    const handleAdd = () => {

        if (!caption || caption === "") {
            setMessage(t("title_mandatory"));
            setIserror(true);
            return;
        }

        if (!upload.length || !imageURL.length || (upload.length !== imageURL.length)) {
            setMessage(t("file_invalid"));
            setIserror(true);
            return;
        }

        let uploads = [...fileUploads];
        for (let i=0; i < upload.length; i++)
        {
            uploads.push({caption: `${caption} (${i+1})`, file: upload[i], imageURL: imageURL[i].imageURL, ext: imageURL[i].ext});
        }
        
        setFileUploads(uploads);
        setUpload([]);
        setImageURL([]);
        setPop(false);

    }

    const deleteImage = (index: number) => {

        let uploads = [...fileUploads];
        if (index > -1) {
            uploads.splice(index, 1); // 2nd parameter means remove one item only
        }
        setFileUploads(uploads);

    }

    // Delete already saved image
    const handleDelete = (index: number) => {

        let uploads = [...files];
        if (index > -1) {
            uploads.splice(index, 1); // 2nd parameter means remove one item only
        }

        const upReport = () => {
            return new Promise((resolve, reject) => {
                api.put('/dailyReports/'+reportID, { files: uploads }).then(res => {
        
                    return resolve(res.data);
                
                }).catch(err => reject(err));

            });
        }

        setShowLoading(true);
        upReport()
        .then(data => {
            setFiles(uploads);
            setPass(t("report_updated"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
    }

    // Submit report if Today ie mode = 'submit', else Save Report mode = 'save'
    const handleReport = (mode: string) => {

        if (!work || work === "") {
            setMessage(t("work_mandatory"));
            setIserror(true);
            return;
        }

        if (!selectedDay) {
            setMessage(t("event_date"));
            setIserror(true);
            return;
        }

        const byear = selectedDay.year;
        const bmonth = selectedDay.month;
        const zeromonth = ('0'+bmonth).slice(-2);
        const bday = selectedDay.day;
        const zeroday = ('0'+bday).slice(-2);
        const calendarDate = new Date(`${byear}-${zeromonth}-${zeroday}T06:00:00.000`);
    
        //If reportID !== "" && fileUploads.length === 0 => Just update work
        //If reportID !== "" && fileUploads.length > 0 => update work & send files
        //If reportID === "" && fileUploads.length === 0 -> add report with just work
        //If reportID === "" && fileUploads.length > 0 -> add report with work and files

        const addReport = () => {
            return new Promise((resolve, reject) => {
              api.post('/dailyReports/add', {'instituteID': authInfo.user.instituteID, 'employeeID': authInfo.user._id, 'name': authInfo.user.name, work, calendarDate, mode}).then(res => {
                return resolve(res.data.dailyReport);
              }).catch(err => reject(err));
            });
        };

        const addReportAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/dailyReports/attach/add', formData).then(res => {
                    return resolve(res.data.dailyReport);
              }).catch(err => reject(err));
            });
        };

        const upReport = () => {
            return new Promise((resolve, reject) => {
                api.put('/dailyReports/'+reportID, { work, mode }).then(res => {
                    return resolve(res.data);
                }).catch(err => reject(err));
            });
        };

        const upAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/dailyReports/attach/update', formData).then(res => {
                    return resolve(res.data.dailyReport);
                }).catch(err => reject(err));
            });
        };

        setShowLoading(true);

        if (reportID !== "")
        {
            if (fileUploads.length === 0)
            {
                upReport()
                .then(data => {
                    setMode(mode);
                    setPass(t("report_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                    //
                })
                .finally(() => setShowLoading(false));
            }
            else
            {
                let formData = new FormData();

                formData.append('reportID', reportID);
                formData.append('work', work);
                formData.append('mode', mode);

                for (let i=0; i < fileUploads.length; i++)
                {
                    formData.append('report[]', fileUploads[i].file, fileUploads[i].caption);
                }
    
                setShowLoading(true);
                upAttach(formData)
                .then(data => {
                    setFileUploads([]);
                    setMode(mode);
                    setPass(t("report_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                   //
                })
                .finally(() => setShowLoading(false));
            }
        }
        else
        {
            if (fileUploads.length === 0)
            {
                addReport()
                .then(data => {
                    setMode(mode);
                    setPass(t("report_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                    //
                })
                .finally(() => setShowLoading(false));
            }
            else
            {
                let formData = new FormData();

                formData.append('instituteID', authInfo.user.instituteID);
                formData.append('employeeID', authInfo.user._id);
                formData.append('name', authInfo.user.name);
                formData.append('work', work);
                formData.append('theDate', `${byear}-${zeromonth}-${zeroday}T06:00:00.000`);
                formData.append('mode', mode);

                for (let i=0; i < fileUploads.length; i++)
                {
                    formData.append('report[]', fileUploads[i].file, fileUploads[i].caption);
                }
    
                setShowLoading(true);
                addReportAttach(formData)
                .then(data => {
                    setFileUploads([]);
                    setMode(mode);
                    setPass(t("report_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                    //
                })
                .finally(() => setShowLoading(false));
            }
        }
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());
                // Default selected on IonDate Popover - max date removed to allow saving of future reports
    
                const minDate = {
                    day: 1,
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
                setMinDay(minDate);

                const maxDate = {
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
                setMaxDay(maxDate);

                const events = await api.get(`/dailyReports/getOneByMonth/${authInfo.user._id}/${onDate.toISOString()}`);
                let result: Array < Attend > = [];
                for (let i = 0; i < events.data.length ; i++) {
                    let dated: Date = new Date(events.data[i].calendarDate);
                    let dateA: number = dated.getDate();

                    let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                    result.push(calObj);
            
                }
                setAttend(result);
                
                const todayDate = {
                    day: onDate.getDate(),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
                setSelectedDay(todayDate);
                setToday(todayDate);

                const res = await api.post('/dailyReports/getOneByDay', {'employeeID': authInfo.user._id, 'day': todayDate});
                if (res.data)
                {
                    setReportID(res.data._id);
                    setWork(res.data.work);
                    setFiles(res.data.files);
                    setResponse(res.data.response);
                    setRating(res.data.rating);
                    setMode(res.data.mode);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };
      
        fetchData();
     
    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/user/tasks`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("daily_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("daily_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }

                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("report_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">{t("month")}</IonLabel>
                                            <IonInput value={selectedDate === '' ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("ok")}
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-padding">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        {(minDay && maxDay) && (
                   
                            <Calendar 
                                value={selectedDay}
                                onChange={handleDate}
                                minimumDate={minDay}
                                maximumDate={maxDay}
                                shouldHighlightWeekends={true}
                                customDaysClassName={attend}
                                calendarClassName="custom-calendar"
                            />
                       
                         )}
                        </IonCol>
                    </IonRow>
                    {(selectedDay && today && (
                    <>
                        {(rating !== undefined) && (rating !== 0) && (
                        <>
                        <IonRow className="mt-30">
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("admin_rating")}
                                    </IonTitle>
                            </IonCol>
                        </IonRow>
                        <IonRow className="mt-10">
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonLabel className='ml-30'>
                                   
                                        {(rating === 1) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 2) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 3) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 4) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 5) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                                                    
                                    </IonLabel>
                            </IonCol>
                        </IonRow>
                        </>
                        )}
                        {(response !== undefined) && (response !== "") && (
                        <>
                        <IonRow className="mt-30">
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("admin_response")}
                                </IonTitle>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            >
                                            <IonLabel position="floating" color="dark">{t("response_details")}</IonLabel>
                                            <IonTextarea rows={6} autoGrow={true} value={response} readonly={true}></IonTextarea>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        </>
                        )}
                        <IonRow className="ion-margin-top">
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonNote className='border-bottom-sec ion-padding'>
                                    {t("work_details_for")} {formatDated(selectedDay)}<br/>
                                    {(mode === "submit") ? t("report_submitted") : (mode === "save") ? t("report_saved") : t("report_not") }
                                </IonNote>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            >
                                            <IonLabel position="floating" color="dark">{t("work_details")}</IonLabel>
                                            <IonTextarea rows={6} autoGrow={true} value={work} disabled={checkPast(selectedDay, today)} onIonInput={
                                                    (e) => setWork(e.detail.value!)
                                            }></IonTextarea>
                                        </IonItem>
                                                
                                            
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {!checkPast(selectedDay, today) && (
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonCard className='action-card-one'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem lines="none"
                                         color="primary"
                                        button={true}
                                        onClick={() => setPop(true)}
                                        >
                                            <IonIcon icon={addCircleOutline}
                                                slot="start" />
                                            <IonLabel className="action-title-default"><h3>{t("attach_files")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                                <IonPopover className='upload-popup' isOpen={pop} onDidDismiss={e => setPop(false)}>
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonLabel position="stacked" color="secondary">{t("title")}*</IonLabel>
                                                        <IonInput type="text"
                                                            value={caption}
                                                            placeholder={t("title")}
                                                            onIonInput={
                                                                (e) => setCaption(e.detail.value !)
                                                        }></IonInput>
                                                    </IonItem>
                                                    {isPlatform("capacitor") && (
                                                    <IonItem detail={true}
                                                        lines='none'
                                                        button={true}
                                                        onClick={takePhoto}
                                                        >
                                                        <IonIcon
                                                            slot="start"
                                                            icon={camera}
                                                        />
                                                        <IonLabel>
                                                            {t("take_photo")}
                                                        </IonLabel>
                                                        
                                                    </IonItem>
                                                    )}
                                                    <IonItem detail={true}
                                                        lines='none'
                                                        button={true}
                                                        onClick={pickImages}
                                                        >
                                                        <IonIcon
                                                            slot="start"
                                                            icon={images}
                                                        />
                                                        <IonLabel>
                                                            {t("upload_photos")}
                                                        </IonLabel>
                                                        
                                                    </IonItem>
                                                    <IonItem detail={true}
                                                        lines='none'
                                                        button={true}
                                                        onClick={pickVideo}
                                                        >
                                                        <IonIcon
                                                            slot="start"
                                                            icon={videocam}
                                                        />
                                                        <IonLabel>
                                                            {t("upload_video")}
                                                        </IonLabel>
                                                            
                                                    </IonItem>
                                                    <IonItem detail={true}
                                                        lines='none'
                                                        button={true}
                                                        onClick={pickFile}
                                                        >
                                                        <IonIcon
                                                            slot="start"
                                                            icon={attach}
                                                        />
                                                        <IonLabel>
                                                            {t("attach_doc")}
                                                        </IonLabel>
                                                            
                                                    </IonItem>
                                                    {(upload.length > 0) && (
                                                    <IonItem detail={true}
                                                            color="danger"
                                                            detailIcon={trash}
                                                            lines='none'
                                                            button={true}
                                                            onClick={() => {
                                                                setUpload([]);
                                                                setImageURL([]);
                                                            }}
                                                        >
                                                        <IonBadge color="light" slot="start">{upload.length}</IonBadge>
                                                        <IonLabel>
                                                            {t("remove_files")}
                                                        </IonLabel>
                                                        
                                                    </IonItem>
                                                    )}
                                                
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="ion-padding-horizontal">
                                            <IonButton onClick={handleAdd}
                                                expand="block"
                                                color="secondary">{t("add_files")}</IonButton>

                                        </IonCol>
                                    </IonRow>
                                </IonPopover>
                            </IonCol>
                        </IonRow>
                        )}
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                {(files.length > 0) && (files.map((afile, i) => {
                                    return (

                                        <IonCard key={i}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonItem lines="none"
                                                button={false}
                                                >
                                                    <IonIcon icon={isFileImage(afile.filename) ? imageOutline : isFileVideo(afile.filename) ? videocam : attach}
                                                        slot="start"
                                                        color="tertiary" 
                                                        onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)} />
                                                    <IonLabel className="action-title-two" onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)}><h3>{afile.caption}</h3></IonLabel>
                                                    {(mode === "save") && (
                                                    <IonIcon icon={trashOutline}
                                                        slot="end"
                                                        color="danger"
                                                        onClick={() => handleDelete(i)} />
                                                    )}
                                                    <IonIcon icon={chevronForwardOutline}
                                                        slot="end"
                                                        color="dark"
                                                        onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)} />

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>

                                    )}))}

                                    {(fileUploads.length > 0) && (fileUploads.map((afile, i) => {
                                    return (

                                        <IonCard key={i}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonItem lines="none"
                                                button={false}
                                                >
                                                    <IonIcon icon={((afile.ext === "jpeg") || (afile.ext === "png")) ? imageOutline : ((afile.ext === "mp4") || (afile.ext === "mov")) ? videocam : attach}
                                                        slot="start"
                                                        color="tertiary"
                                                        onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext) } />
                                                    <IonLabel className="action-title-two" onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext)}><h3>{afile.caption}</h3></IonLabel>
                                                    <IonIcon icon={trashOutline}
                                                        slot="end"
                                                        color="danger"
                                                        onClick={() => deleteImage(i)} />
                                                    <IonIcon icon={chevronForwardOutline}
                                                        slot="end"
                                                        className='ml-16'
                                                        color="dark"
                                                        onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext)} />

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>

                                    )}))}

                                    {(fileUploads.length === 0) && (files.length === 0) && (
                                        <IonCard className='red-card'>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start" 
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_files_uploaded")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>

                                    )}
                            </IonCol>
                        </IonRow>

                    </>
                    ))}

                </IonGrid>
            </IonContent>
            {selectedDay && today && !checkPast(selectedDay, today) && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(JSON.stringify(selectedDay) === JSON.stringify(today)) && (
                            <IonButton onClick={() => handleReport('submit')}
                                className="first-button"
                                fill="clear"
                                > {t("submit_daily_report")}</IonButton>
                            )}
                            {(mode !== "submit") && (
                            <IonButton onClick={() => handleReport('save')}
                                className="second-button"
                                fill="clear"
                                > {t("save_daily_report")}</IonButton>
                            )}

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            )}
        </IonPage>
    );
}

export default DailyReports;
