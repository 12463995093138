
import {
    IonAlert,
    IonAvatar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { helpCircleOutline, time } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Calendar, Day } from '@hassanmojab/react-modern-calendar-datepicker';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

interface ParamTypes {
    employeeID: string
}
interface Employee {
    userInfo: UserInfo,
    photo?: string,
    departmentInfo: DepInfo,
    designation?: string
}
interface DepInfo {
    departmentID: string,
    name: string
}
interface UserInfo {
    name: string,
    role: string
}
interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}
interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

const EmployeeAttendance: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {employeeID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [employee, setEmployee] = useState < Employee > ();
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);
    const [attendance, setAttendance] = useState < Attendance[] > ([]);

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const formatDate = (value: string) => {
        const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
                            t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
                        ];

        const valueDate = new Date(value);
        return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    };

    const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string")
        {
            setSelectedDate(theDate);

            const onDate = new Date(theDate);

            const minDate = {
                year: onDate.getFullYear(),
                month: onDate.getMonth()+1,
                day: 1
            }

            setMinDay(minDate);

            const maxDate = {
                year: onDate.getFullYear(),
                month: onDate.getMonth()+1,
                day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1)
            }

            setMaxDay(maxDate);

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const studs = await api.get(`/staffAttends/getByEmployee/${employeeID}/${theDate}`);

                    let result: Array < Attend > = [];

                    for (let i = 0; i < studs.data.length ; i++) {
                            let dated: Date = new Date(studs.data[i].dated);
                            let dateA: number = dated.getDate();

                            let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: studs.data[i].status}; 
                            result.push(calObj);
                    
                    }
                    setAttend(result);

                } catch (error: any) {
                   //
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/employees/${employeeID}`);
                setEmployee(result.data);

                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());
    
                const minDate = {
                    year: onDate.getFullYear(),
                    month: onDate.getMonth()+1,
                    day: 1
                }
    
                setMinDay(minDate);
    
                const maxDate = {
                    year: onDate.getFullYear(),
                    month: onDate.getMonth()+1,
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1)
                }
    
                setMaxDay(maxDate);
    
                const studs = await api.get(`/staffAttends/getByEmployee/${employeeID}/${onDate.toISOString()}`);
    
                let res: Array < Attend > = [];
    
                for (let i = 0; i < studs.data.length ; i++) {
                    let dated: Date = new Date(studs.data[i].dated);
                    let dateA: number = dated.getDate();

                    let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: studs.data[i].status}; 
                    res.push(calObj);
                
                }

                setAttend(res);

                const attend = await api.get(`/staffAttends/getLogEmployee/${employeeID}`);
                setAttendance(attend.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();
     
    }, [authInfo, employeeID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("employee_attendance")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("employee_attendance")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="profile-card">
                                <IonCardContent>
                                        <IonRow>
                                            <IonCol size="3" className='ion-text-right'>
                                                <IonAvatar className="leader-avatar">
                                                    {employee && employee.photo && (employee.photo !== "") ?
                                                        (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${employee.photo}`} alt={t("profile_pic")} />)
                                                        : (<img src={`assets/images/avatar1.png`} alt={t("profile_pic")} />)
                                                    }
                                                </IonAvatar>
                                            </IonCol>
                                            <IonCol size="9" className='pl-08 mt-05'>
                                                <IonText className='action-text-3'>{employee?.userInfo.name}<br/>
                                                {employee && employee.designation !== "" && `${employee.designation} (`} {employee && employee.departmentInfo.name} {employee && employee.designation !== "" && `)`}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">{t("month")}</IonLabel>
                                            <IonInput value={selectedDate === '' ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle id="open-attendance-info" className='border-bottom-sec'>
                                {t("attendance_log")}
                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                            </IonTitle>
                            
                            <IonPopover trigger="open-attendance-info" className='info-popover mt-20'>
                                <IonCard className='note-card-5 ion-no-margin'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol size="4" className="box">
                                                <div className="present-info"></div>{t("present")}
                                            </IonCol>
                                            <IonCol size="4" className="box">
                                                <div className="absent-info"></div>{t("absent")}
                                            </IonCol>
                                            <IonCol size="4" className="box">
                                                <div className="late-info"></div>{t("late")}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size="4" className="box">
                                                <div className="half-info"></div>{t("half")}
                                            </IonCol>
                                            <IonCol size="4" className="box">
                                                <div className="leave-info"></div>{t("leave")}
                                            </IonCol>
                                            <IonCol size="4" className="box">
                                                <div className="holiday-info"></div>{t("holiday")}
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonPopover>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top ion-padding no-click">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                            <Calendar 
                                value={minDay}
                                minimumDate={minDay}
                                maximumDate={maxDay}
                                shouldHighlightWeekends={true}
                                customDaysClassName={attend}
                                calendarSelectedDayClassName="selected-day"
                                calendarClassName="custom-calendar"
                            />
                            
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className='ion-padding profile-text-1'>
                            {t("marked_present")}: {attend.filter(obj => (obj.className === "present")).length} {t("day_s")}
                        </IonCol>
                    </IonRow>
                    {(attendance.length > 0) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonCardSubtitle className='info-subtitle pl-16 pt-08'>
                                    {t("employee_attend_log")}
                                    </IonCardSubtitle>
                                </IonCol>
                                <IonCol size="6" className='ion-text-right'>
                                {(authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (<IonButton color="dark" size="small" className='pr-16' onClick={() => history.push(`/app/attend/staff/log/${employeeID}`)}>{t("view_all")}</IonButton>)}
                                {(authInfo.user.role === "Academic" || authInfo.user.roles.includes("Academic")) && (employee && employee.userInfo.role === "Teacher") && (<IonButton color="dark" size="small" className='pr-16' onClick={() => history.push(`/app/attendance/teacher/log/${employeeID}`)}>{t("view_all")}</IonButton>)}
                                {(authInfo.user.role === "Transporter" || authInfo.user.roles.includes("Transporter")) && (employee && employee.userInfo.role === "Driver") &&  (<IonButton color="dark" size="small" className='pr-16' onClick={() => history.push(`/app/attendance/driver/log/${employeeID}`)}>{t("view_all")}</IonButton>)}
                                {(authInfo.user.role === "AdmissionHead" || authInfo.user.roles.includes("AdmissionHead")) && (employee && employee.userInfo.role === "SalesRep") &&  (<IonButton color="dark" size="small" className='pr-16' onClick={() => history.push(`/app/attendance/salesrep/log/${employeeID}`)}>{t("view_all")}</IonButton>)}
                                
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                          <IonList className="ion-no-padding">
                             {(attendance.map((attend, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={attend._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                           >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    ((attend.status === 'in')) ? 'success' : 'danger'
                                                }/>
                                                <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                    <h3>
                                                    {(attend.status === 'out') && (
                                                        <p>{t("exit_at")} {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'in') && (
                                                        <p>{t("entry_at")} {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                  </h3>
                                                </IonLabel>
                                                
                                        </IonItem>
                                    )
                                }))
                                } 
                            </IonList>
                        </IonCol>
                    </IonRow>
                    </>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default EmployeeAttendance;
