import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import usePageUnloadHandler from '../../components/PageUnloadHandler';

interface Workshop {
    _id: string,
    name: string,
    photo: string,
    duration: string,
    status: string,
    attendees: number,
    applicationFees: number,
    startDate: Date
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: parseInt(process.env.REACT_APP_API_TO!)
});

const AllWorkshops: React.FC = () => {
    const history = useHistory();
    const { authInfo } = useAuth()!;
    const { t } = useTranslation();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [workshops, setWorkshops] = useState<Workshop[]>([]);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const scrollContent = useRef<HTMLIonContentElement | null>(null);
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const [skip, setSkip] = useState<number>(0);
    const [refreshed, setRefreshed] = useState<number>(0);

    // Refresh handler
    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    // Scroll end handler
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    }

    // Format date helper
    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    // Load items from API
    const loadItems = async () => {
        let limit = skip + 30;

        try {
            const result = await api.get(`/admissionCourses/listAll/${process.env.REACT_APP_APP_ID}?skip=${limit}`);

            if (result.data && result.data.length) {
                setWorkshops([...workshops, ...result.data]); 
                setSkip(limit); // Update skip if new workshops are added
            }
                
        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    // Handle side effects
    useEffect(() => {
        const storedState = sessionStorage.getItem(history.location.pathname);

        if (history.action === "POP" && storedState) {
            const { workshops, skip, scrollPosition } = JSON.parse(storedState);
            setWorkshops(workshops);
            setSkip(skip);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } else {
            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const result = await api.get(`/admissionCourses/listAll/${process.env.REACT_APP_APP_ID}`);
                    setWorkshops(result.data);
                    setSkip(0);
                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
            };

            fetchUp();
        }
        setInitialRender(false);
    }, [refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) {
            const currentState = {
                workshops,
                skip,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [workshops, skip, scrollPosition, history.location.pathname, initialRender]);

   // Effect to handle page reload 
   usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("all_workshops")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("all_workshops")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />


                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
              
                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                           
                            
                            {(workshops.length > 0) ? (workshops.map((workshop) => {
                                return (
                                <IonCard key={workshop._id} className="ion-margin-vertical border-radius-10">

                                    <IonCardContent className="ion-no-padding">
                                            
                                        <IonRow className="ion-margin-horizontal">
                                            {(workshop.photo) ? (
                                            <>
                                                <IonCol size="4" className='ion-padding-vertical'>
                                                    <img src={`${process.env.REACT_APP_STATIC_S3}/images/${workshop.photo}`} alt={workshop.name} style={{'width': '100%', 'height': 'auto'}} />
                                                </IonCol>
                                                <IonCol size="8" className='ion-padding'>
                                                    <p><span className="class-name" >{workshop.name}</span></p>
                                                    <p><span className="class-info">{t("project_start")}: {formatDate(workshop.startDate)}</span></p>
                                                    <p><span className="class-info">{t("duration")}: {workshop.duration}</span></p>
                                                </IonCol>
                                            </>
                                            ) : (
                                            <IonCol className='ion-padding'>
                                                    <p><span className="class-name" >{workshop.name}</span></p>
                                                    <p><span className="class-info">{t("project_start")}: {formatDate(workshop.startDate)}</span></p>
                                                    <p><span className="class-info">{t("duration")}: {workshop.duration}</span></p>
                                            </IonCol>
                                            )}
                                            
                                        </IonRow>
                                        <IonRow className={(authInfo.loggedIn !== true) ? "ion-margin-horizontal pb-08 blur-row" : "ion-margin-horizontal pb-08"}
                                            onClick={() => (authInfo.loggedIn !== true) ? history.push(`/login`) : history.push(`/app/workshop/${workshop._id}`)}>
                                            <IonCol size="8" className='mt-05'>
                                                <p><span className="class-info"> {t("total_cost")} {t("rs")} {workshop.applicationFees}/-</span></p>
                                            </IonCol>
                                            <IonCol size="4" className="ion-text-right">
                                                <IonButton color="secondary" size="small">{t("attend")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                                
                                    </IonCardContent>
                                </IonCard>
                                 )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_workshops")}</h3></IonLabel>
    
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                            } 

                        </IonCol>
                    </IonRow>

                    {(workshops.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }
                  
                 
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default AllWorkshops;