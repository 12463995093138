import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, call, chatbubblesOutline, checkboxOutline, informationCircle, logoWhatsapp, mailOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/PageUnloadHandler';
import useApiService from '../../../../components/ApiService';

interface Inquiry {
    _id: string,
    source: string,
    type: string,
    status: string,
    userInfo: User,
    assignedTo?: EmpObj,
    updates: Update[],
    createdAt: Date,
    updatedAt: Date,
    isChecked?: boolean
}
interface EmpObj {
    employeeID: string,
    employeeName: string
}

interface User {
    userID: string,
    name: string,
    phone: string,
    email: string
}

interface Update {
    _id: string,
    timestamp: Date,
    userInfo: UserInfo,
    update: UpdateInfo
}

interface UserInfo {
    userID: string,
    userName: string
}

interface UpdateInfo {
    text: string,
    status: string
}

interface AdmnClass {
    masterID: string,
    name: string,
    annualCost: number,
    applicationFees: number,
    isActive: boolean
}
interface Employee {
    _id: string,
    userInfo: UserInfoEmp,
    isActive: boolean
}
interface UserInfoEmp {
    userID: string,
    name: string
}

const Inquiries: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [classes, setClasses] = useState < AdmnClass[] > ([]);
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [status, setStatus] = useState < string > ("all");
    const [source, setSource] = useState < string > ("all");
    const [type, setType] = useState < string > ("all");
    const [employeeID, setEmployeeID] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [searchText, setSearchText] = useState < string > ("");
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [buttonColor, setButtonColor] = useState < string > ("light");
    const [selectedEmployee, setSelectedEmployee] = useState < string > ("none");
    const [showReps, setShowReps] = useState < boolean > (false);

    const openURL = (value: string) => {

        var url = value;
        if (!/^https?:\/\//i.test(value)) {
            url = 'http://' + value;
        }

        window.open(url, '_system');
        return false;
    };
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) setScrollPosition(elem.scrollTop);
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string") {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        } else {
            return t("pick_date");
        }
    };

    const selectAll = () => {

        if (buttonColor === "light")
        {
            let studs = [...inquiries];
            for (let i=0; i < studs.length; i++)
            {
                studs[i].isChecked = true;
            }
            setInquiries(studs);
            setButtonColor("success");
        }
        else
        {
            let studs = [...inquiries];
            for (let i=0; i < studs.length; i++)
            {
                studs[i].isChecked = false;
            }
            setInquiries(studs);
            setButtonColor("light");
        }
        
    }

    const handleChecked = (id : number) => {
        
        let studs = [...inquiries];
        studs[id].isChecked = !studs[id].isChecked;
        setInquiries(studs);
    }

    const assignSelected = () => {

        if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'AdmissionHead') || (authInfo.user.roles.includes('AdmissionHead')))
        {
            const checkedInquiryIds = inquiries
            .filter((inquiry) => inquiry.isChecked) // Filter inquiries where isChecked is true
            .map((inquiry) => inquiry._id);

            if (!checkedInquiryIds.length)
            {
                setMessage(t("checked_mandatory"));
                setIserror(true);
                return;
            }

            let employeeName = "Nobody";
            if (selectedEmployee !== "none")
            {
                employeeName = employees.find(val => val.userInfo.userID === selectedEmployee)!.userInfo.name;
            }

            setShowLoading(true);
            api.post('/admissionInquiries/bulkReassign', { employeeID: selectedEmployee, employeeName, adminID: authInfo.user._id, adminName: authInfo.user.name, checkedInquiryIds })
            .then((res: any) => {
                setShowReps(false);
                setPass(t("admn_inquiries_assigned")+employeeName);
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
        
    }

    const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/admissionInquiries/listPendingInstitute/${authInfo.user.instituteID}`, {status, type, source, employeeID, toDOB, fromDOB, searchText});
                setInquiries(result.data.inquiries);
                setTotal(result.data.total);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    };

    const loadItems = async () => {

        let limit = skip + 30;
       
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        try {
            const result = await api.post(`/admissionInquiries/listPendingInstitute/${authInfo.user.instituteID}?skip=${limit}`, {status, type, source, employeeID, toDOB, fromDOB, searchText});

            if(result.data.inquiries && result.data.inquiries.length)
            {
                setInquiries([...inquiries, ...result.data.inquiries]); 
                setSkip(limit);
            }

        } catch (error: any) {
            //
        }
        
    };

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            // Data is present in local storage, use it to initialize state
            const { inquiries, classes, total, skip, status, source, type, employees, employeeID, toDate, fromDate, searchText, scrollPosition } = JSON.parse(storedState);

            setInquiries(inquiries);
            setClasses(classes);
            setTotal(total);
            setSkip(skip);
            setStatus(status);
            setSource(source);
            setType(type);
            setEmployees(employees);
            setEmployeeID(employeeID);
            setToDate(toDate);
            setFromDate(fromDate);
            setSearchText(searchText);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } 
        else
        {
            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const res = await api.get(`/institutes/${authInfo.user.instituteID}`);
                    
                    if (res.data.admnClasses)
                        setClasses(res.data.admnClasses);
                    else
                        setClasses([]);

                    if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'AdmissionHead') || (authInfo.user.roles.includes('AdmissionHead')))
                    {
                        const result = await api.post(`/admissionInquiries/listPendingInstitute/${authInfo.user.instituteID}`, {
                            status: "all", type: "all", source: "all", employeeID: "all", toDOB: "", fromDOB: "", searchText: ""
                        });
                        setInquiries(result.data.inquiries);
                        setTotal(result.data.total);

                        const studs = await api.get(`/employees/getSalesReps/${authInfo.user.instituteID}`);
                        setEmployees(studs.data);
                        setEmployeeID("all");
                    }
                    if ((authInfo.user.role === 'SalesRep') || (authInfo.user.roles.includes('SalesRep')))
                    {
                        setEmployeeID(authInfo.user._id);

                        const result = await api.post(`/admissionInquiries/listPendingInstitute/${authInfo.user.instituteID}`, {
                            status: "all", type: "all", source: "all", employeeID: authInfo.user._id, toDOB: "", fromDOB: "", searchText: ""
                        });
                        setInquiries(result.data.inquiries);
                        setTotal(result.data.total);
                    }
                    setSkip(0);
                    setType("all");
                    setSource("all");
                    setStatus("all");
                    setSearchText("");
                    setToDate(undefined);
                    setFromDate(undefined);

                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
        setInitialRender(false);

    }, [authInfo, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                inquiries,
                classes,
                total,
                skip,
                status,
                source,
                type,
                employees,
                employeeID,
                toDate,
                fromDate,
                searchText,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [inquiries, classes, total, skip, status, source, type, employees, employeeID, toDate, fromDate, searchText, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("enquiries_list")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonModal isOpen={showReps} showBackdrop={true} onDidDismiss={() => setShowReps(false)}
                initialBreakpoint={0.5} breakpoints={[0, 0.5]} handle={true}>

                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                    <IonToolbar>
                        <IonTitle>{t("assign_selected")}</IonTitle>
                    </IonToolbar>
                    <IonRow>
                        <IonCol>
                            <IonCard className='ion-no-margin ion-margin-horizontal'>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        color="primary"
                                    >
                                        <IonBadge slot="start" color="light">{inquiries.filter((inquiry) => inquiry.isChecked).length}</IonBadge>
                                        <IonLabel>
                                            {t("enquiries_selected")}
                                        </IonLabel>
                                        
                                    </IonItem>
                                    <IonItem
                                        detail={false}
                                        lines='full'
                                        button={false}
                                    >
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("assign_to")}</IonLabel>
                                        <IonSelect value={selectedEmployee}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            className="text-capitalize"
                                            placeholder={t("select_emp")}
                                            onIonChange={
                                                (e) => e.detail.value && setSelectedEmployee(e.detail.value)
                                        }>
                                            <IonSelectOption value="none">{t("no_one")}</IonSelectOption>
                                            {employees.map((employee) => (
                                                (employee.isActive) &&
                                                (<IonSelectOption key={employee._id} value={employee.userInfo.userID} className='text-capitalize'>{employee.userInfo.name}</IonSelectOption>)
                                            ))}
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={assignSelected}
                                className="first-button"
                                fill="clear"
                                >{t("assign")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonContent>
                
            </IonModal>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("enquiries_list")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xxl">
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("name")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("name")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'AdmissionHead') || (authInfo.user.roles.includes('AdmissionHead'))) && (                                 
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem
                                        detail={false}
                                        lines='none'
                                        button={false}
                                    >
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("assigned_to")}</IonLabel>
                                        <IonSelect value={employeeID}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            className="text-capitalize"
                                            placeholder={t("select_emp")}
                                            onIonChange={
                                                (e) => e.detail.value && setEmployeeID(e.detail.value)
                                        }>
                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            {employees.map((employee) => (
                                                (employee.isActive) &&
                                                (<IonSelectOption key={employee._id} value={employee.userInfo.userID} className='text-capitalize'>{employee.userInfo.name}</IonSelectOption>)
                                            ))}
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        <IonCol size="1.5">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("inquiry_status")}</IonLabel>
                                            <IonSelect value={status}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("inquiry_status")}
                                                    onIonChange={
                                                        (e) => e.detail.value && setStatus(e.detail.value)
                                                }>
                                                    <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    <IonSelectOption value="new">{t("new")}</IonSelectOption>
                                                    <IonSelectOption value="unreachable">{t("unreachable")}</IonSelectOption>
                                                    <IonSelectOption value="shared">{t("shared")}</IonSelectOption>
                                                    <IonSelectOption value="followup">{t("followup")}</IonSelectOption>
                                                    <IonSelectOption value="meeting">{t("meeting")}</IonSelectOption>
                                                    <IonSelectOption value="converted">{t("converted")}</IonSelectOption>
                                                    <IonSelectOption value="failed">{t("failed")}</IonSelectOption>
                                                    <IonSelectOption value="disqualified">{t("disqualified")}</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("inquiry_type")}</IonLabel>
                                            <IonSelect value={type}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("inquiry_type")}
                                                    onIonChange={
                                                        (e) => setType(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                        <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                                    {classes.map((classy, i) => (
                                                       
                                                        (<IonSelectOption key={i} value={classy.name}>{classy.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.5">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("source")}
                                        </IonLabel>
                                        <IonSelect value={source}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            placeholder={t("source")}
                                            onIonChange={
                                                (e) => setSource(e.detail.value)
                                            }>
                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            <IonSelectOption value="google">{t("google")}</IonSelectOption>
                                            <IonSelectOption value="instagram">{t("instagram")}</IonSelectOption>
                                            <IonSelectOption value="facebook">{t("facebook")}</IonSelectOption>
                                            <IonSelectOption value="whatsapp">{t("whatsapp")}</IonSelectOption>
                                            <IonSelectOption value="youtube">{t("youtube")}</IonSelectOption>
                                            <IonSelectOption value="linkedin">{t("linkedin")}</IonSelectOption>
                                            <IonSelectOption value="justdial">{t("justdial")}</IonSelectOption>
                                            <IonSelectOption value="website">{t("website")}</IonSelectOption>
                                            <IonSelectOption value="walkin">{t("walkin")}</IonSelectOption>
                                            <IonSelectOption value="newspaper">{t("newspaper")}</IonSelectOption>
                                            <IonSelectOption value="magazine">{t("magazine")}</IonSelectOption>
                                            <IonSelectOption value="poster">{t("poster")}</IonSelectOption>
                                            <IonSelectOption value="reference">{t("reference")}</IonSelectOption>
                                            <IonSelectOption value="other">{t("other")}</IonSelectOption>
                                            
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.5">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("from_date")}
                                            </IonLabel>
                                            <IonInput value={formatDated(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("from_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1.5">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("to_date")}
                                            </IonLabel>
                                            <IonInput value={formatDated(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("to_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_enquiries")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="3" size="5" className='table-heading'>
                            {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'AdmissionHead') || (authInfo.user.roles.includes('AdmissionHead'))) && (
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color={buttonColor}
                                    className='table-icon'
                                    onClick={selectAll}
                                />
                            )}
                                <IonText className='ion-margin-start'>{t("admn_inquiry_details")}</IonText>
                            </IonCol>

                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("type")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("leave_status")}
                            </IonCol>
                            <IonCol sizeLg="2" size="4" className='table-heading'>
                            {t("last_update")}
                            </IonCol>
                            <IonCol size="3" className='table-heading ion-text-center'>
                            {t("actions")}
                            </IonCol>
                        </IonRow>
                            {(inquiries.length > 0) ? (inquiries.map((inquiry, i) => {
                                return (
                                    <IonRow key={inquiry._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                        onDoubleClick={() => history.push(`/app/admission/inquiry/${inquiry._id}`)}>
                                        
                                            <IonCol sizeLg="3" size="5" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'AdmissionHead') || (authInfo.user.roles.includes('AdmissionHead'))) && (
                                                        <>
                                                        <IonCheckbox labelPlacement="end" checked={inquiry.isChecked} className='mb-10'
                                                            onClick={(e) => handleChecked(i) }></IonCheckbox>
                                                        <IonText className='text-capitalize ion-margin-start'>{inquiry.userInfo.name}</IonText>
                                                        </>
                                                    )}
                                                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'AdmissionHead') || (authInfo.user.roles.includes('AdmissionHead')))
                                                    && inquiry.assignedTo && inquiry.assignedTo.employeeID !== "none" && (<><br/><IonText className='text-capitalize vid-responsive-field-1 ion-hide-lg-down '> {t("assigned_to")}: <Link to={`/app/admission/inquiries/reports/salesrep/${inquiry.assignedTo.employeeID}`} className='link-dark'>{inquiry.assignedTo.employeeName}</Link> </IonText></>)}
                                                    
                                                    {((authInfo.user.role === 'SalesRep') || (authInfo.user.roles.includes('SalesRep'))) && (
                                                        <IonText className='text-capitalize'>{inquiry.userInfo.name}</IonText>
                                                    )}
                                                    
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {inquiry.type}  
                                                        {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'AdmissionHead') || (authInfo.user.roles.includes('AdmissionHead'))) 
                                                        && inquiry.assignedTo && inquiry.assignedTo.employeeID !== "none" && (<IonText> &#8226; <Link to={`/app/admission/inquiries/reports/salesrep/${inquiry.assignedTo.employeeID}`} className='link-dark'>{inquiry.assignedTo.employeeName}</Link> </IonText>)}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {inquiry.type}
                                            </IonCol>
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            <IonBadge className='ml-05' color={(inquiry.status === "new") ? "medium" : ((inquiry.status === "shared") || (inquiry.status === "unreachable"))
                                                ? "secondary" : (inquiry.status === "converted") ? "success" : ((inquiry.status === "failed") || (inquiry.status === "disqualified")) ? "danger" : "warning"}>
                                                    {t(inquiry.status)}
                                                </IonBadge>
                                                        
                                            </IonCol>
                                            <IonCol sizeLg="2" size="4" className='table-field'>
                                                <IonText className='ion-hide-lg-up'>  <IonBadge className='ml-05' color={(inquiry.status === "new") ? "medium" : ((inquiry.status === "shared") || (inquiry.status === "unreachable"))
                                                ? "secondary" : (inquiry.status === "converted") ? "success" : ((inquiry.status === "failed") || (inquiry.status === "disqualified")) ? "danger" : "warning"}>
                                                    {t(inquiry.status)}
                                                </IonBadge> </IonText> 
                                                
                                                {t("on")} {formatDate(inquiry.updatedAt)}
                                                {(inquiry.updates.length > 0) && (<IonText className='text-capitalize'> {t("marked_by")} {inquiry.updates[(inquiry.updates.length - 1)].userInfo.userName}</IonText>)}
                                            </IonCol>
                                            <IonCol size="3" >
                                                <IonRow className='ion-margin-top'>
                                                    <IonCol size="6" className='ion-text-center'>
                                                        <IonIcon 
                                                            icon={informationCircle}
                                                            color="dark"
                                                            className='table-icon'
                                                            onClick={
                                                                () => history.push(`/app/admission/inquiry/${inquiry._id}`)
                                                            }
                                                        />
                                                    </IonCol>
                                                    <IonCol size="6" className='ion-text-center'>
                                                        <IonIcon 
                                                            icon={call}
                                                            color="tertiary"
                                                            className='table-icon'
                                                            onClick={
                                                                () => document.location.href = `tel:${inquiry.userInfo.phone}`
                                                            }
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-margin-bottom'>
                                                    {inquiry.userInfo.email !== "" && (
                                                    <IonCol size="6" className='ion-text-center'>
                                                        
                                                        <IonIcon 
                                                            icon={mailOutline}
                                                            color="secondary"
                                                            className='table-icon mt-15'
                                                            onClick={
                                                                () => document.location.href = `mailto:${inquiry.userInfo.email}`
                                                            }
                                                        />
                                                       
                                                    </IonCol>
                                                    )}
                                                    {(inquiry.userInfo.phone.match(/^[0-9]{10}$/)) && (
                                                    <IonCol size="6" className='ion-text-center'>
                                                        <IonIcon 
                                                            icon={logoWhatsapp}
                                                            color="success"
                                                            className='table-icon mt-15'
                                                            onClick={
                                                                () => openURL(`https://wa.me/+91${inquiry.userInfo.phone}`)
                                                            }
                                                        />
                                                    </IonCol>
                                                    )}
                                                    {(inquiry.userInfo.userID.match(/^[0-9a-fA-F]{24}$/)) && (
                                                
                                                        <IonCol size="6" className='ion-text-center'>
                                                            
                                                            <IonIcon 
                                                                icon={chatbubblesOutline}
                                                                color="primary"
                                                                className='table-icon mt-15'
                                                                onClick={
                                                                    () => history.push(`/app/admission/inquiry/${inquiry._id}/chat`)
                                                                }
                                                            />
                                                        
                                                        </IonCol>
                                                    )}
                                                </IonRow>
                                               
                                            </IonCol>
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_open_enq")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(inquiries.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${inquiries.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-lg'>

                            {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'AdmissionHead') || (authInfo.user.roles.includes('AdmissionHead'))) && (
                             <IonButton 
                                onClick={() => setShowReps(true) }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("assign_selected")}</IonButton>
                            )}

                            <IonButton 
                                onClick={
                                    () => history.push(`/app/admission/inquiries/new`)
                                      
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("new_admn_inq")}</IonButton>

                            <IonButton 
                                onClick={
                                    () => history.push(`/app/admission/inquiries/board`)
                                      
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_enq_board")}</IonButton>

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("export_excel")}</IonButton>

                        </IonCol>
                    </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Inquiries;