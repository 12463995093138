import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { alertCircleOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';

interface Substitute {
    _id: string,
    classInfo: ClassInfo,
    timeSlotInfo: Slot,
    subjectInfo: Subject,
    subTeacher: Teacher,
    originalTeacher: Teacher,
    createdAt: Date
}


interface Slot {
    timeSlotID: string,
    startTime: string,
    endTime: string
}

interface Subject {
    subjectID: string,
    subjectName: string
}

interface Teacher {
    teacherID: string,
    teacherName: string
}

interface ClassInfo {
    classID: string,
    className: string
}

const SubHistory: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [substitutes, setSubstitutes] = useState < Substitute[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);

    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/substitutes/getByInstitute/${authInfo.user.instituteID}?skip=${limit}`);

            if(result.data)
            {
                setSubstitutes([...substitutes, ...result.data]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            //
        }
        
    }

    const getDate = (utcdate: Date) => {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/substitutes/getByInstitute/${authInfo.user.instituteID}`);
                setSubstitutes(result.data);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("substitutes_history")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("substitutes_history")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">

                   <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("list_substitutes")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                  
 
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(substitutes.length > 0) ? substitutes.map((sub) => (
                            <IonCard className='border-radius-10 mt-15' key={sub._id}>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow className="tran-pad title-row">
                                            <IonCol size="3" className=''>
                                                <IonText>{sub.classInfo.className}</IonText>
                                            </IonCol>
                                            <IonCol size="9" className='ion-text-right'>
                                                <IonText>{sub.timeSlotInfo.startTime} to {sub.timeSlotInfo.endTime} on {getDate(sub.createdAt)}</IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='border-top'>
                                            <IonCol>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    >
                                                    
                                                    <IonLabel position="stacked" color="secondary">{t("original_teacher")}</IonLabel>
                                                    <IonInput type="text"
                                                            value={sub.originalTeacher.teacherName}
                                                            readonly={true}
                                                            ></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    >
                                                    
                                                    <IonLabel position="stacked" color="secondary">{t("substitute_teacher")}</IonLabel>
                                                    <IonInput type="text"
                                                            value={sub.subTeacher.teacherName}
                                                            readonly={true}
                                                            ></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        
                                    
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                            )) : (
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start" 
                                        color="danger" />
                                        <IonLabel className="list-label"><h3>{t("no_substitutes")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )}
                        </IonCol>
                    </IonRow>
                        
                    {(substitutes.length > 0) &&
                        <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }
                  
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default SubHistory;
