import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { cartOutline, star, starOutline, trashOutline } from 'ionicons/icons';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface Application {
    _id: string,
    applicationType: string,
    amount: number,
    status: string,
    classInfo: ClassInfo,
    instituteInfo: InstituteInfo,
    appointmentInfo?: AppointmentInfo,
    response?: string,
    rating?: number,
    childInfo: ChildInfo,
    formData: AppForm[],
    createdAt: Date
}

interface AppointmentInfo {
    dated: Date,
    info: string
}

interface ClassInfo {
    classID: string,
    className: string
}

interface InstituteInfo {
    instituteID: string,
    instituteName: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

interface AppForm {
    name: string,
    category: string,
    value: string
}

interface ParamTypes {
    applicationID: string
}

const ApplicationDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {applicationID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [application, setApplication] = useState < Application > ();
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [response, setResponse] = useState < string > ("");

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const handleDelete = () => {
  
        const deleteApp = () => {
            return new Promise((resolve, reject) => {
                api.put(`/applications/${applicationID}`, {status: 'removed'}).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        }
  
        setShowLoading(true);
        deleteApp()
        .then(() => {
          setShowLoading(false);
          history.push('/app/dashboard');
        })
        .catch(error => {
          //
          setShowLoading(false);
        });
        
    }

    const handleSubmit = (rating: number) => {
  
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put(`/applications/${applicationID}`, { rating }).then(res => {
                return resolve(res.data);
              }).catch(err => reject(err));
            });
        }
      
        setShowLoading(true);
        upApli()
        .then(data => {

            setPass(t("feedback_updated"));
            setIspass(true);
        
        })
        .catch((error) => {
           //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleReview = () => {

        if (!response || response === "") {
            setMessage(t("response_mandatory"));
            setIserror(true);
            return;
        }
    
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put(`/applications/${applicationID}`, { response }).then(res => {
                return resolve(res.data);
              }).catch(err => reject(err));
            });
        }
      
        setShowLoading(true);
        upApli()
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const appp = await api.get(`/applications/${applicationID}`);
                setApplication(appp.data);
                if (appp.data.response)
                {
                    setResponse(appp.data.response);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };
     
        fetchData();

    }, [authInfo, refreshed, applicationID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{application && (application.applicationType === "admission") ? t("application_form") : t("workshop_details")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{application && (application.applicationType === "admission") ? t("application_form") : t("workshop_details")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_delete_app")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(application) && (
                            <IonCard className={(application.applicationType === "workshop") ? "ion-margin-vertical planner-card-3 ion-padding" : "ion-margin-vertical class-card"}>

                                <IonCardContent className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonRow className="pb-08">
                                                    <IonCol size="8">
                                                        <p><span className="class-name" >{application.instituteInfo.instituteName}</span></p>
                                                                
                                                        <p><span className="class-info">{application.classInfo.className} &#8226; {application.childInfo.childName}</span></p>
                                                        <p><span className="class-info-2"> {formatDate(application.createdAt)}</span></p>

                                                    </IonCol>
                                                    <IonCol size="4">
                                                    
                                                    {(application.status === "unpaid") && (
                                                        <>
                                                            <IonIcon icon={cartOutline} size="large" color="success"
                                                            className='pl-08'
                                                            onClick={() => {
                                                                history.push(`/app/application/${applicationID}`);
                                                            }}/>

                                                        <IonIcon icon={trashOutline} size="large" color="danger" className="pl-08"
                                                                onClick={() => setShowAlert(true)}/>
                                                        </>
                                                    )}
                                                    
                                                        </IonCol>
                                                </IonRow>
                                                <IonRow className="pr-16">
                                                    <IonCol size="8" className='mt-05'>
                                                        <p><span className="class-info-3">{t("app_fees")} {application.amount}/-</span></p>
                                                        
                                                    </IonCol>
                                                    <IonCol size="4" className="ion-text-right">
                                                        {(application.status === "upcoming") && (
                                                            <IonBadge color="warning">{t("upcoming")}</IonBadge>
                                                        )}
                                                        {(application.status === "pending") && (
                                                            <IonBadge color="warning">{t("processing")}</IonBadge>
                                                        )}
                                                        {(application.status === "shortlisted") && (
                                                            <IonBadge color="secondary">{t("appointment")}</IonBadge>
                                                        )}
                                                        {(application.status === "approved") && (
                                                            <IonBadge color="success">{t("admitted")}</IonBadge>
                                                        )}
                                                        {(application.status === "completed") && (
                                                            <IonBadge color="success">{t("completed")}</IonBadge>
                                                        )}
                                                        {(application.status === "cancelled") && (
                                                            <IonBadge color="danger">{t("cancelled")}</IonBadge>
                                                        )}
                                                        {(application.status === "rejected") && (
                                                            <IonBadge color="danger">{t("rejected")}</IonBadge>
                                                        )}
                                                            {(application.status === "removed") && (
                                                            <IonBadge color="danger">{t("deleted")}</IonBadge>
                                                        )}
                                                    </IonCol>
                                                </IonRow>
                                                {(application.status === "shortlisted") && application.appointmentInfo && (
                                                <>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p><span className="class-info-3">{t("appointment_date")} {formatDated(application.appointmentInfo.dated)}</span></p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p><span className="class-info-4">{application.appointmentInfo.info}</span></p>
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                                )}
                                                {((application.status === "rejected") || (application.status === "approved")) && application.response && (
                                            
                                                    <IonRow>
                                                        <IonCol>
                                                            <p><span className="class-info-4">{application.response}</span></p>
                                                        </IonCol>
                                                    </IonRow>
                                                
                                                )}
                                                    {(application.appointmentInfo && (application.applicationType === "workshop")) && 
                                                (<IonRow>
                                                    <IonCol><p><span className="class-info-3">{t("project_start")}: {formatDated(application.appointmentInfo.dated)}</span><br/>
                                                        <span className="class-info-3">{t("duration")}: {application.appointmentInfo.info}</span></p>
                                                    </IonCol>
                                                </IonRow>)}
                                            </IonCol>
                                        
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                            )}
                        </IonCol>
                            
                    </IonRow>
                    
                    {application && (application.applicationType === "admission") && (<>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("application_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("childs_details")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Child') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("mothers_details")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Mother') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("fathers_details")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Father') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("address_details")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Address') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("childs_documents")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'ChildDocument') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>
                                  
                                                        <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`, admnField.name)}
                                                        src={`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`} 
                                                        alt={admnField.name}
                                                        width="320" 
                                                        className='ion-padding-vertical'
                                                        />
                                       
                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("other_documents")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Document') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                            <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`, admnField.name)}
                                                            src={`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`} 
                                                            alt={admnField.name}
                                                            width="320" 
                                                            className='ion-padding-vertical'
                                                            />
                                       
                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>

                        </IonCol>
                    </IonRow>
                    </>)}
                    {application && (application.applicationType === "workshop") && (application.status === "completed") && (<>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("workshop_feedback")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonLabel className='ml-30'>
                                    {!application.rating && (
                                            <>
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {application.rating && (application.rating === 1) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {application.rating && (application.rating === 2) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {application.rating && (application.rating === 3) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {application.rating && (application.rating === 4) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {application.rating && (application.rating === 5) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                                                    
                                    </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonLabel position="floating" color="dark">{t("feedback_details")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={response} onIonInput={
                                                (e) => setResponse(e.detail.value!)}></IonTextarea>
                                    </IonItem>
                                            
                                        
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>)}
                </IonGrid>
            </IonContent>
            {application && (application.applicationType === "workshop") && (application.status === "completed") && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={handleReview}
                                className="first-button"
                                fill="clear"
                                > {t("submit_feedback")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            )}
        </IonPage>
    );
}

export default ApplicationDetail;