import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { addCircle, alertCircleOutline, arrowUndoCircle, attach, camera, closeCircle, trash } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import useApiService from '../../../../components/ApiService';

interface ParamTypes {
    topicID: string,
    questionID: string
}

const QuestionDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {topicID, questionID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [solutionFile, setSolutionFile] = useState < File | null > (null);
    const [mode, setMode] = useState < string > ("Question");
    const [oldOptions, setOldOptions] = useState < string[] > ([]);
    const [options, setOptions] = useState < string[] > ([]);
    const [title, setTitle] = useState < string > ("");
    const [statement, setStatement] = useState < string > ("");
    const [solution, setSolution] = useState < string > ("");
    const [statementImage, setStatementImage] = useState < string > ("none");
    const [solutionImage, setSolutionImage] = useState < string > ("none");
    const [answer, setAnswer] = useState < number > ();
    const [refreshed, setRefreshed] = useState < number > (0);

    const handleUndo = () => {
        setOptions(oldOptions);
    };

    const handleOption = (option: string, index: number) => {
        let comps = [...options];
        comps[index] = option;
        setOptions(comps);
    };

    const handleDelete = (index: number) => {
        let comps = [...options];
        if (index > -1) {
            comps.splice(index, 1); // 2nd parameter means remove one item only
          }
        setOptions(comps);
    };

    const handleAdd = () => {
        let comps = [...options];
        comps.push("");
        setOptions(comps);
    };

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const takeQuePhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions().then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: 'image/jpeg',
                                    });
                                    setUploadFile(rawFile);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: 'image/jpeg',
                            });
                            setUploadFile(rawFile);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };
      
    const pickQueFile = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const takeSolPhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions().then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: 'image/jpeg',
                                    });
                                    setSolutionFile(rawFile);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: 'image/jpeg',
                            });
                            setSolutionFile(rawFile);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickSolFile = async () => {
        try {

           const result = await FilePicker.pickImages({limit: 1});

           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                   const webPath = convertFileSrc(file.path);
                   const blob = await fetch(webPath).then((r) => r.blob());
                   const rawFile = new File([blob], file.name, {
                       type: file.mimeType,
                   });
                   setSolutionFile(rawFile);
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                   const rawFile = new File([file.blob], file.name, {
                       type: file.mimeType,
                   });
                   setSolutionFile(rawFile);
               }
           }
           
       } 
       catch(error: any) {

           setMessage(t("no_files_selected"));  
           setIserror(true);
       }
   };

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("question_title_mandatory"));
            setIserror(true);
            return;
        }

        if (!statement || statement === "") {
            setMessage(t("question_statement_mandatory"));
            setIserror(true);
            return;
        }

        if (!solution || solution === "") {
            setMessage(t("solution_statement_mandatory"));
            setIserror(true);
            return;
        }

        if(options.length < 2) {
            setMessage(t("options_mandatory"));
            setIserror(true);
            return;
        }

        for (let i = 0; i < options.length; i++)
        {
            if(options[i] === "") {
                setMessage(t("statement_mandatory"));
                setIserror(true);
                return;
            }
        }

        if (typeof answer !== 'number') {
            setMessage(t("answer_mandatory"));
            setIserror(true);
            return;
        }

        if (answer >= options.length) {
            setMessage(t("answer_mandatory"));
            setIserror(true);
            return;
        }
  
        const addQuestion = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/topics/addQuestion', formData).then(res => {
                    return resolve(res.data.title);
                }).catch(err => reject(err));
            });
        }

        let formData = new FormData();

        if (uploadFile) {
            formData.append('statementFile', uploadFile, uploadFile.name);
        }
        if (solutionFile) {
            formData.append('solutionFile', solutionFile, solutionFile.name);
        }

        formData.append('topicID', topicID);
        formData.append('questionID', questionID);
        formData.append('title', title);
        formData.append('statement', statement);
        formData.append('solution', solution);
        formData.append('statementImage', statementImage);
        formData.append('solutionImage', solutionImage);
        formData.append('options', JSON.stringify(options));
        formData.append('answer', answer.toString());

        setShowLoading(true);
        addQuestion(formData)
        .then(data => {
            setPass(data+t('has_been_updated'));
            setIspass(true);
        })
        .catch((error) => {
            // Api Error handling is already done in the interceptor
        })
        .finally(() => setShowLoading(false));

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/topics/getQuestion/${topicID}/${questionID}`);
                setTitle(result.data.title);

                if(result.data.statementImage)
                {
                    setStatementImage(result.data.statementImage);
                }
                if(result.data.statement)
                {
                    setStatement(result.data.statement);
                }
                if(result.data.solutionImage)
                {
                    setSolutionImage(result.data.solutionImage);
                }
                if(result.data.solution)
                {
                    setSolution(result.data.solution);
                }
                if(result.data.options)
                {
                    setOldOptions(result.data.options);
                    setOptions(result.data.options);
                }
                if(typeof result.data.answer === 'number')
                {
                    setAnswer(result.data.answer);
                }
                
            } catch (error: any) {
                // Api Error handling is already done in the interceptor
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, topicID, questionID, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/courses/topic/${topicID}`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("topic_question")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("topic_question")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />
                
                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t('question_title')}</IonLabel>
                                        <IonInput type="text"
                                            className="input-field"
                                            placeholder={t('question_title')}
                                            value={title}
                                            onIonInput={
                                                (e) => setTitle(e.detail.value!)
                                        }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding mt-15">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonSegment value={mode} onIonChange={e => setMode( e.detail.value!.toString() )} mode="ios" className='isactive-segment'>
                                <IonSegmentButton value="Question">
                                    <IonLabel>{t('question')}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="Options">
                                    <IonLabel>{t('options')}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="Solution">
                                    <IonLabel>{t('solution')}</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    {(mode === "Question") && (
                    <>
                    <IonRow className='ion-margin-vertical'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className='ion-no-padding ion-no-margin'>
                                    <IonList className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="floating" color="secondary">{t("problem_statement")}*</IonLabel>
                                            <IonTextarea rows={12} value={statement} onIonInput={
                                                    (e) => setStatement(e.detail.value!)
                                            }></IonTextarea>

                                        </IonItem>

                                        {(statementImage === "none") && (
                                            <>
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={isPlatform("capacitor") ? takeQuePhoto : pickQueFile}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                        {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(uploadFile) && (
                                                <IonItem detail={true}
                                                        detailIcon={trash}
                                                        lines='none'
                                                        color="danger"
                                                        button={true}
                                                        onClick={() => setUploadFile(null)}
                                                    >
                                                    <IonBadge color="light" slot="start">1</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                            </>
                                        )}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                            
                        
                        </IonCol>
                    </IonRow>

                            {(statementImage !== "none") && (
                            <>
                            <IonRow>
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard className="ion-text-center">
                                        <IonCardHeader>
                                            <IonCardSubtitle>{t("problem_image")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            
                                            <img
                                                src={`${process.env.REACT_APP_STATIC_S3}/images/${statementImage}`} 
                                                alt={t("problem_image")}
                                                width="320" 
                                            />
                                            
                                        </IonCardContent>        
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top mb-60">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonRow>
                                        <IonCol size="6" className='ion-padding-horizontal'>
                                            <IonButton expand="block" color="dark" onClick={isPlatform("capacitor") ? takeQuePhoto : pickQueFile}>{t("change")}</IonButton> 

                                        </IonCol>
                                        <IonCol size="6" className='ion-padding-horizontal'>
                                            <IonButton expand="block" color="danger" onClick={() => setStatementImage("none")}>{t("remove")}</IonButton> 
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            
                        </>
                        )}

                    </>
                    )}

                    {(mode === "Options") && (
                    <>
                     <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                            <IonRow>
                                <IonCol size="8" className='mt-05'>
                                    <IonText className='list-title ion-padding-start'>
                                        {t("answer_options")}
                                    </IonText>
                                </IonCol>
                                <IonCol size="2" className='ion-text-right'>
                                    <IonIcon size="large"
                                        icon={arrowUndoCircle}
                                        color='secondary'
                                        onClick={handleUndo}
                                    />
                                </IonCol>
                                <IonCol size="2" className='ion-text-center'>
                                    <IonIcon size="large"
                                        icon={addCircle}
                                        color='secondary'
                                        onClick={handleAdd}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                   
                    {(options.length > 0) ? (
                    <IonRow className='ion-margin-vertical'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonList className="ion-no-padding">
                                <IonRadioGroup value={answer} onIonChange={
                                            (e) => setAnswer(e.detail.value!)
                                    }>
                                {options.map((option, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={i}>
                                                <IonLabel position="floating" color="secondary">{t("option")} #{i+1} {t("statement")}</IonLabel>
                                                <IonRadio slot="start" value={i} className="mt-40"></IonRadio>
                                                <IonTextarea rows={3} value={option} onIonInput={
                                                        (e) => handleOption(e.detail.value!, i)
                                                }></IonTextarea>
                                                
                                                <IonButtons slot="end">
                                                    <IonButton onClick={() => handleDelete(i)}> 
                                                        <IonIcon
                                                        icon={closeCircle}
                                                        color="danger"
                                                        />
                                                    </IonButton>
                                                </IonButtons>
                                        </IonItem>
                                            )
                                        })
                                    }
                                </IonRadioGroup>
                            </IonList>
                                
                        </IonCol>
                    </IonRow>
                    ) : (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='red-card'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_options")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                </>
                )}

                    {(mode === "Solution") && (
                    <>
                        <IonRow className='ion-margin-vertical'>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonCard>
                                    <IonCardContent className='ion-no-padding ion-no-margin'>
                                        <IonList className="ion-no-padding">

                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="floating" color="secondary">{t("solution_statement")}*</IonLabel>
                                                    <IonTextarea rows={12} value={solution} onIonInput={
                                                            (e) => setSolution(e.detail.value!)
                                                    }></IonTextarea>
                                                
                                                </IonItem>

                                            {(solutionImage === "none") && (
                                             <>
                                             
                                             <IonItem detail={true}
                                                 lines='none'
                                                 button={true}
                                                 onClick={isPlatform("capacitor") ? takeSolPhoto : pickSolFile}
                                                 >
                                                 <IonIcon
                                                     slot="start"
                                                     icon={camera}
                                                 />
                                                 <IonLabel>
                                                    {(solutionFile) ? t("change_photo") : t("upload_photo")}
                                                 </IonLabel>
                                                 
                                             </IonItem>
                                             {(solutionFile) && (
                                             <IonItem detail={true}
                                                    detailIcon={trash}
                                                    color="danger"
                                                     lines='none'
                                                     button={true}
                                                     onClick={() => setSolutionFile(null)}
                                                 >
                                                 <IonBadge color="light" slot="start">1</IonBadge>
                                                 <IonLabel>
                                                     {t("remove_files")}
                                                 </IonLabel>
                                                 
                                             </IonItem>
                                             )}

                                         </>
                                            )}

                                        </IonList>
                                    </IonCardContent>
                                </IonCard>
                        </IonCol>
                    </IonRow>
                    {(solutionImage !== "none") && (
                            <>
                            <IonRow>
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard className="ion-text-center">
                                        <IonCardHeader>
                                            <IonCardSubtitle>{t("solution_image")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            
                                            <img
                                                src={`${process.env.REACT_APP_STATIC_S3}/images/${solutionImage}`} 
                                                alt={t("solution_image")}
                                                width="320" 
                                            />
                                            
                                        </IonCardContent>        
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top mb-60">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonRow>
                                        <IonCol size="6" className='ion-padding-horizontal'>
                                            <IonButton expand="block" color="dark" onClick={isPlatform("capacitor") ? takeSolPhoto : pickSolFile}>{t("change")}</IonButton> 

                                        </IonCol>
                                        <IonCol size="6" className='ion-padding-horizontal'>
                                            <IonButton expand="block" color="danger" onClick={() => setSolutionImage("none")}>{t("remove")}</IonButton> 

                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                           
                        </>
                        )}
                    </>
                    )}
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonButton onClick={handleSubmit}
                            className="first-button"
                            fill="clear">{t("submit_question")}</IonButton>

                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    );
};

export default QuestionDetail;
