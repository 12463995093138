import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alertCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface Application {
    _id: string,
    amount: number,
    classInfo: ClassInfo,
    appointmentInfo: AppointmentInfo,
    instituteInfo: InstituteInfo,
    childInfo: ChildInfo,
    createdAt: Date
}

interface ClassInfo {
    classID: string,
    className: string
}

interface AppointmentInfo {
    dated: Date,
    info: string
}

interface InstituteInfo {
    instituteID: string,
    instituteName: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

const WorkshopHistory: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [pendingApps, setPendingApps] = useState < Application[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/applications/getAttendedWorkshops/${authInfo.user._id}?skip=${limit}`);
            if(result.data.applications && result.data.applications.length)
            {
                setPendingApps([...pendingApps, ...result.data.applications]);
                setSkip(limit);
            }

        } catch (error: any) {
            //
        }
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const unpaid = await api.get(`/applications/getAttendedWorkshops/${authInfo.user._id}`);
                setPendingApps(unpaid.data.applications);
                setTotal(unpaid.data.total);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("attended_workshops")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("attended_workshops")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                        
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">

                            {(pendingApps.length > 0) && pendingApps.map((app) => {
                                return (
                                        <IonCard key={app._id} className="ion-margin-vertical planner-card-3 ion-padding" >

                                            <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonRow className="pb-08">
                                                                <IonCol size="10.5">
                                                                    <p><span className="class-name" >{app.instituteInfo.instituteName}</span></p>
                                                                    
                                                                    <p><span className="class-info">{app.classInfo.className} &#8226; {app.childInfo.childName}</span></p>
                                                                    <p><span className="class-info-2"> {formatDate(app.createdAt)}</span></p>
                                                                </IonCol>
                                                                <IonCol size="1.5">
                                                                    <p><IonIcon icon={informationCircleOutline} size="large" color="success"
                                                                    onClick={() => {
                                                                        history.push(`/app/form/${app._id}`);
                                                                    }}/>
                                                                    </p>
                                                                    
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p><span className="class-info-3">{t("project_start")} {formatDated(app.appointmentInfo.dated)}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p><span className="class-info-4">{t("duration")} {app.appointmentInfo.info}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                      
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                    })
                                }
                                {(pendingApps.length === 0) && (
                                     <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger"
                                                    size="large" />
                                                <IonLabel className="list-title"><h3>{t("no_workshop_history")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )}
                        </IonCol>
                    </IonRow>

                    {(pendingApps.length > 0) &&
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }
                  
                </IonGrid>
            </IonContent>
            <IonFooter className=' footer-shade'>
                <IonGrid>
                    <IonRow className='ion-text-center'>
                        <IonCol className="ion-padding footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${pendingApps.length} ${t("of")} ${total}`} 
                        </IonCol>
                      
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default WorkshopHistory;