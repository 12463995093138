import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { camera, close, trash } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import QRCode from 'react-qr-code';
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import SwitchStudent from '../../components/SwitchStudent';
import useApiService from '../../components/ApiService';

const UpdateStudent: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [studentID, setStudentID] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > ('');
    const [house, setHouse] = useState < string > (t("no_house"));
    const [fileName, setFileName] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [blood, setBlood] = useState < string > ("");
    const [disability, setDisability] = useState < boolean > (false);
    const [disableInfo, setDisableInfo] = useState < string > ("");
    const [medicalInfo, setMedicalInfo] = useState < string > ("");
    const [gender, setGender] = useState < string > ("");
    const [className, setClassName] = useState < string > ("");
    const [classSession, setClassSession] = useState < string > ("");
    const [showModal, setShowModal] = useState < boolean > (false);
    const [imageURL, setImageURL] = useState < string > ("");

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: string | string[]) => {
        if (typeof value === 'string')
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
    };

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };
      
    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const takePhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions().then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: 'image/jpeg',
                                    });
                                    setUploadFile(rawFile);
                                    setImageURL(webPath);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: 'image/jpeg',
                            });
                            setUploadFile(rawFile);
                            setImageURL(webPath);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickImage = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        setImageURL(webPath);
                    }
                    else
                    {
                        setImageURL("");
                    }
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const base64 = await fileToDataUri(rawFile);
                        setImageURL(base64);
                    }
                    else
                    {
                        setImageURL("");
                    }
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const handlePhoto = () => {

        if (!uploadFile) {
            setMessage(t("logo_format"));
            setIserror(true);
            return;
        }

        const upStud = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/students/updatePhoto', formData).then(res => {
                return resolve(res.data.student.name);
              }).catch(err => reject(err))
            });
        }

        // Change uploadFile to blob in native implementation using capacitor plugin
        let formData = new FormData();
        formData.append('image', uploadFile, uploadFile.name);
        formData.append('studentID', studentID);
    
        setShowLoading(true);
        upStud(formData)
        .then(data => {
        
            setPass(data+t("has_been_updated"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage(t("address_mandatory"));
            setIserror(true);
            return;
        }

        if (!gender || gender === "") {
            setMessage(t("gender_mandatory"));
            setIserror(true);
            return;
        }

        if(disability)
        {
            if (!disableInfo || disableInfo === "") {
                setMessage(t("disability_mandatory"));
                setIserror(true);
                return;
            }
        }

        const upStudent = (dob: string | string[]) => {
            return new Promise((resolve, reject) => {
                api.put('/students/'+studentID, { name, address, dob, gender, blood, disability, disableInfo, medicalInfo }).then(res => {
                    return resolve(res.data.name);
                }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        upStudent(selectedDate)
      .then(data => {

          setPass(data+t("has_been_updated"));
          setIspass(true);
        
      })
      .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudentID(result.data._id);
                setName(result.data.name);
                setFileName(result.data.photo);
                setAddress(result.data.address);
                setSelectedDate(result.data.dob);
                setClassName(result.data.classInfo.className);
                setClassSession(result.data.classInfo.session);

                if (result.data.house && result.data.house !== "")
                {
                    setHouse(result.data.house);
                }
                if(result.data.gender)
                {
                    setGender(result.data.gender);
                }
                if(result.data.bloodType)
                {
                    setBlood(result.data.bloodType);
                }
                if(result.data.medicalInfo)
                {
                    setMedicalInfo(result.data.medicalInfo);
                }

                setDisability(result.data.isDisabled);
                if(result.data.disabilityInfo)
                {
                    setDisableInfo(result.data.disabilityInfo);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/user/profile`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_profile")}</IonTitle>
                     {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_profile")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonModal isOpen={showModal}
                    onDidDismiss={
                        () => setShowModal(false)
                }>

                    <IonContent className="grey-shade">
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton onClick={
                                        () => setShowModal(false)
                                    }>
                                        <IonIcon icon={close} size="large" />
                                    </IonButton>
                                </IonButtons>
                                <IonTitle color="secondary">{t("qr_code")}</IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonCard className="mt-60 receipt-shade">
                            <IonCardContent className='mt-48'>

                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <QRCode value={`https://prathmic.com/app/qr/student/${studentID}`} />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-text-center mt-30 mb-30">
                                        <IonText className="profile-text">{name}</IonText><br/>
                                        {(className !== "") && (<IonText className="profile-text">{className} ({classSession})</IonText>)}
                                    </IonCol>
                                </IonRow> 

                            </IonCardContent>
                        </IonCard>
                       
                    </IonContent>
                </IonModal>

                <SwitchStudent refreshed={refreshed} setRefreshed={setRefreshed} />

                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol className="ion-text-center" id="open-upload-input" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                    {fileName && (fileName !== "") ?
                                        (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt={t("profile_pic")} width="120" />)
                                        : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} width="120" />)
                                    }
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                        
                    <IonPopover trigger="open-upload-input" className='upload-popup'>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                        <IonItem detail={true}
                                            lines='none'
                                            button={true}
                                            color="primary"
                                            onClick={isPlatform("capacitor") ? takePhoto : pickImage}
                                            >
                                            <IonIcon
                                                slot="start"
                                                icon={camera}
                                            />
                                            <IonLabel>
                                                {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        {(uploadFile) && (
                                        <IonItem detail={true}
                                                detailIcon={trash}
                                                lines='none'
                                                button={true}
                                                onClick={() => {
                                                    setUploadFile(null);
                                                    setImageURL("");
                                                }}
                                            >
                                            <IonBadge color="danger" slot="start">1</IonBadge>
                                            <IonLabel>
                                                {t("remove_files")}
                                            </IonLabel>
                                            
                                        </IonItem>
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {imageURL && (
                                <IonRow>
                                    <IonCol>
                                        <IonCard className="ion-text-center">
                                            <IonCardHeader>
                                                <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                            
                                                <img src={imageURL} 
                                                    alt={t("image_preview")}
                                                    width="120" />

                                            </IonCardContent>        
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )}
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonButton onClick={handlePhoto}
                                    size="small"
                                    className="first-button"
                                    fill="clear">{t("update_photo")}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonPopover>
                    
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <p><span className="profile-text">{name}</span><br/>
                            {(className !== "") && (<span className='pt-05'>{className} ({classSession})</span>)}
                            </p>

                        </IonCol>
                    </IonRow>

   
                    <IonRow className="ion-margin-top">
                    
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonRow className="ion-margin-horizontal">
                                    <IonCol>
                                        <IonButton onClick={ () => setShowModal(true)}
                                            expand="block" color="dark">{t("qr_code")}</IonButton>
                                    </IonCol>
                                    <IonCol>
                                    <IonButton onClick={ () => history.push("/app/user/profile/student/location")}
                                         expand="block" color="primary" >{t("view_location")}</IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-margin-horizontal">
                                    <IonCol>
                                        <IonButton onClick={ () => history.push(`/app/user/profile/student/guardians`)}
                                            expand="block" color="secondary">{t("guardian_info")}</IonButton>
                                    </IonCol>
                                </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='list-title'>
                                 {t("student_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    {/** 
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("student_name")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("student_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    */}
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("student_gender")}*
                                            </IonLabel>
                                            <IonSelect value={gender}
                                                className="input-field"
                                                placeholder={t("student_gender")}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                onIonChange={
                                                    (e) => setGender(e.detail.value)
                                                }>
                                            
                                                <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                <IonSelectOption value="other">{t("other")}</IonSelectOption> 
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("student_dob")}*
                                            </IonLabel>
                                            <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("student_dob")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={selectedDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("student_address")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("student_address")}
                                                value={address}
                                                onIonInput={
                                                    (e) => setAddress(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='list-title'>
                                {t("medical_info")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                                
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("student_blood")}
                                            </IonLabel>
                                            <IonSelect value={blood}
                                                className="input-field"
                                                placeholder={t("student_blood")}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                onIonChange={
                                                    (e) => setBlood(e.detail.value)
                                                }>
                                            
                                                <IonSelectOption value="A+">A+</IonSelectOption>
                                                <IonSelectOption value="A-">A-</IonSelectOption> 
                                                <IonSelectOption value="B+">B+</IonSelectOption>
                                                <IonSelectOption value="B-">B-</IonSelectOption>      
                                                <IonSelectOption value="O+">O+</IonSelectOption>
                                                <IonSelectOption value="O-">O-</IonSelectOption>      
                                                <IonSelectOption value="AB+">AB+</IonSelectOption>
                                                <IonSelectOption value="AB-">AB-</IonSelectOption>         
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        color="primary"
                                        >
                                        <IonLabel className="input-label">{t("student_disabled")}</IonLabel>
                                        <IonToggle color="secondary" checked={disability} onIonChange={e => setDisability(e.detail.checked)} />
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {disability && (
                        <IonRow>
                            <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel className="input-label" position="floating" color="secondary">
                                                {t("nature_of_disability")}
                                            </IonLabel>
                                            <IonTextarea rows={4} value={disableInfo} onIonInput={
                                                    (e) => setDisableInfo(e.detail.value!)
                                            }></IonTextarea>
                                        
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        
                    )}
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel  className="input-label" position="floating" color="secondary">
                                            {t("allergies")}
                                        </IonLabel>
                                        <IonTextarea rows={4} value={medicalInfo} onIonInput={
                                                (e) => setMedicalInfo(e.detail.value!)
                                        }></IonTextarea>
                                            
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                size="small">{t("update_student_details")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default UpdateStudent;
