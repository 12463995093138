import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, cash } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/PageUnloadHandler';
import useApiService from '../../../../components/ApiService';

interface Vendor {
    _id: string,
    name: string,
    due: number,
    isActive: boolean
}

const VendorReports: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [vendors, setVendors] = useState < Vendor[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
    };

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { vendors, scrollPosition } = JSON.parse(storedState);
            // Data is present in local storage, use it to initialize state
            setVendors(vendors);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } 
        else 
        {

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const res = await api.get(`/vendors/getAllInstitute/${authInfo.user.instituteID}`);
                    setVendors(res.data);

                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
            };

            fetchUp();

        }
        setInitialRender(false);

    }, [authInfo, history.action, history.location.pathname]);

      // Save state to local storage whenever it changes
      useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            vendors,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [vendors, scrollPosition, history.location.pathname, initialRender]);

     // Effect to handle page reload 
     usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/inventory`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("vendors_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("vendors_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

               {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">
                   
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("vendors_balance")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                          <IonList className='ion-no-padding'>
                             {(vendors.length > 0) ? (vendors.map((vendor, i) => {
                                    return (
                                        <IonItem detail={false}
                                            detailIcon={cash}
                                            lines='full'
                                            button={false}
                                            key={vendor._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                        >
                                           
                                            <IonLabel className="list-label text-capitalize" onClick={
                                                    () => history.push(`/app/vendor/ledger/${vendor._id}`)
                                                }
                                            >
                                                <h3>{vendor.name} ({t("rs")} {vendor.due})</h3>
                                            </IonLabel>
                                            <IonIcon slot="end"
                                                icon={cash}
                                                color={
                                                    (vendor.due > 0) ? 'danger' : 'success'
                                                }
                                                onClick={
                                                    () => history.push(`/app/vendor/ledger/${vendor._id}`)
                                                }
                                            />
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start" color="danger" />
                                        <IonLabel className="list-label"><h3>{t("no_vendors")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default VendorReports;
