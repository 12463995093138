import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { addCircle, alertCircleOutline, arrowUndoCircle, home, informationCircleOutline, trash } from 'ionicons/icons';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

const Houses: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [houses, setHouses] = useState < string[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [selectedHouse, setSelectedHouse] = useState < string > ("");
    const popover = useRef<HTMLIonPopoverElement>(null);
    const [pop, setPop] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);

    const handleAdd = () => {

        if (!selectedHouse || selectedHouse === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        let homes = [...houses];
        homes.push(selectedHouse);
        setHouses(homes);

        popover.current?.dismiss();
    }

    const handleRemove = (index: number) => {
        let homes = [...houses];
        if (index > -1) {
            homes.splice(index, 1); // 2nd parameter means remove one item only
          }
        setHouses(homes);
    }

    const handleHouses = () => {
  
        const upInsti = (houses: string[]) => {
            return new Promise((resolve, reject) => {
              api.put('/institutes/'+authInfo.user.instituteID, { houses }).then(res => {
                return resolve(res.data.name);
              }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        upInsti(houses)
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
          
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const insti = await api.get(`/institutes/${authInfo.user.instituteID}`);

                if(insti.data.houses)
                {
                    setHouses(insti.data.houses);
                }
                else
                {
                    setHouses([]);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/essentials`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("school_houses")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("school_houses")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="primary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("houses_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                               
                                <IonRow>
                                    <IonCol size="8" className='ion-padding-start'>
                                        <IonText className='list-title'>
                                        {t("school_houses")}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="2" className='ion-text-right'>
                                    
                                        <IonIcon size="large"
                                            icon={arrowUndoCircle}
                                            color='secondary'
                                            onClick={() => setRefreshed(Math.random())}
                                        />
                                    
                                    
                                    </IonCol>
                                    <IonCol size="2" className='ion-text-right ion-padding-end'>
                                        
                                            <IonIcon size="large"
                                                icon={addCircle}
                                                color='secondary'
                                                onClick={() => setPop(true)}
                                            />

                                    </IonCol>
                                </IonRow>
                                
                        </IonCol>
                    </IonRow>
                  
                    
                    <IonPopover ref={popover} isOpen={pop} size='auto' onIonPopoverDidDismiss={() => setPop(false)}>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                
                                                >
                                                
                                                <IonLabel position="stacked" color="secondary">{t("house_name")}*</IonLabel>
                                                    <IonInput value={selectedHouse}
                                                                    placeholder={t("house_name")}
                                                                    onIonInput={
                                                                        (e) => setSelectedHouse(e.detail.value!)
                                                                }>
                                                              
                                                    </IonInput>
                                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={handleAdd}
                                        expand="block"
                                        className="first-button"
                                        fill="clear">{t("add_house")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                          <IonList className="ion-no-padding">
                             {(houses.length > 0) ? (houses.map((house, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={i}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            >
                                            <IonIcon slot="start"
                                                icon={home}
                                                />
                                            <IonLabel className='list-label'>
                                                <h3>{house}</h3>
                                            </IonLabel>
                                            <IonButtons slot="end">
                                                <IonButton onClick={
                                                    () => handleRemove(i)
                                                }>
                                                    <IonIcon 
                                                        icon={trash}
                                                        color="dark"
                                                        className='table-icon'
                                                    />

                                                </IonButton>
                                            </IonButtons>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start" 
                                        color="danger" />
                                        <IonLabel className="list-label"><h3>{t("no_houses")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonButton onClick={handleHouses}
                                className="first-button"
                                fill="clear" >{t("save_changes")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Houses;
