import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

interface ClassInterface {
    _id: string,
    name: string,
    courseID?: string,
    isActive: boolean
}
interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface Course {
    _id: string,
    name: string,
    courseType: string,
    isActive: boolean
}

interface ParamTypes {
    classID?: string
}

const BatchSchedule: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [courses, setCourses] = useState < Course[] > ([]);
    const [courseID, setCourseID] = useState < string > ("");
   
    const handleClassi = (classID : string) => {

        setClassi(classID);
        const course = classes.find(val => val._id === classID)!.courseID;
        if (course)
        {
            setCourseID(course);
        }
        else
        {
            setCourseID("");
        }

    }

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setCourseID("");
    }

    const handleSubmit = () => {

        if (!courseID || courseID === "") {
            setMessage(t("course_madatory"));
            setIserror(true);
            return;
        }

        const updateBatch = () => {
            return new Promise((resolve, reject) => {
                api.put('/classes/'+classi, { courseID }).then(res => {
                    return resolve(res.data.name);
                }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        updateBatch()
        .then(data => {
            setPass(data+t("has_been_updated"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
    
    }

    useEffect(() => {

        let instituteID: string = "";
        if (process.env.REACT_APP_APP_ID === 'com.bricked.stms') {
            instituteID = authInfo.user.instituteID;
        } else {
            instituteID = process.env.REACT_APP_APP_ID!;
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                const result = await api.get(`/courses/getAllInstitute/${instituteID}`);
                setCourses(result.data);

                if (classID)
                {
                    const classInfo = await api.get(`/classes/${classID}`);
                    setSession(classInfo.data.sessionID);
                    const resu = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                    setClasses(resu.data);
                    setClassi(classID);
                    if(classInfo.data.courseID)
                    {
                        setCourseID(classInfo.data.courseID);
                    }

                }
                else
                {
                    setSession("");
                    setClasses([]);
                    setClassi("");
                    setCourseID("");
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();

    }, [authInfo, classID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("assign_batch_schedule")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("assign_batch_schedule")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("select_batch")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}</IonLabel>
                                                    <IonSelect value={session}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("class_session")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.map((session) => (
                                                                (session.isActive) && 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            ))}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("batch")}</IonLabel>
                                                    <IonSelect value={classi}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_batch")}
                                                            onIonChange={
                                                                (e) => e.detail.value && handleClassi(e.detail.value)
                                                        }>
                                                            
                                                            {classes.map((classy) => (
                                                                (classy.isActive) &&
                                                                (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                            ))}
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>

                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("select_course")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("select_course")}</IonLabel>
                                                <IonSelect value={courseID}
                                                        className="input-field"
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        placeholder={t("select_course")}
                                                        onIonChange={
                                                            (e) => setCourseID(e.detail.value)
                                                    }>

                                                        {courses.map((course) => 
                                                            (<IonSelectOption key={course._id} value={course._id}>{course.name}</IonSelectOption>)
                                                        )}

                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("assign_batch_schedule")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default BatchSchedule;
