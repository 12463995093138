import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    classID?: string
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface TimeSlot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    isActive: boolean,
    isChecked: boolean
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const AddTimeTableOne: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [timeSlots, setTimeSlots] = useState < TimeSlot[] > ([]);
    const [name, setName] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");

    const handleChecked = (value: boolean, id : number) => {

        let slots: Array < TimeSlot > = [];
        for (let i = 0; i < timeSlots.length ; i++) {
            if (i === id) {
                slots[i] = {'_id': timeSlots[i]._id, 'name': timeSlots[i].name, 'startTime': timeSlots[i].startTime, 'endTime': timeSlots[i].endTime, 'isActive': timeSlots[i].isActive, 'isChecked' : value};    
            }
            else 
            {
                slots[i] = timeSlots[i];
            }       
        }
        setTimeSlots(slots);
    }

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
    }


    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("table_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!classi || classi === "") {
            setMessage(t("table_class_mandatory"));
            setIserror(true);
            return;
        }

        let slots: Array < TimeSlot > = [];
        for (let i = 0; i < timeSlots.length ; i++) {
            if (timeSlots[i].isChecked)
                slots.push(timeSlots[i]);
        }

        if(!slots.length)
        {
            setMessage(t("slot_mandatory"));
            setIserror(true);
            return;
        }

        history.push({
            pathname: `/app/timetables/add/subjects`,
            state: { 
                'selectedSlots': slots,
                'classID': classi,
                'name': name
            }});
        
      }

      useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                const slots = await api.get(`/timeSlots/getByInstitute/${authInfo.user.instituteID}`);

                let slotsArray: Array < TimeSlot > = [];
                for (let i = 0; i < slots.data.length ; i++) {
                    let slotObj: TimeSlot = slots.data[i];
                    slotObj['isChecked'] = true;
                    slotsArray.push(slotObj);
                }

                setTimeSlots(slotsArray);

                if (classID)
                {
                    const result = await api.get(`/classes/${classID}`);
                    setSession(result.data.sessionID);

                    const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${result.data.sessionID}`);
                    setClasses(query.data);
                    setClassi(classID);
                } else {
                    setSession("");
                    setClasses([]);
                    setClassi("");
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, classID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("new_timetable")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("new_timetable")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("table_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("table_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("table_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}*
                                                </IonLabel>
                                                    <IonSelect value={session}
                                                            className="input-field"
                                                            placeholder={t("class_session")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.map((session) => 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    )}

                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("select_class")}*
                                                </IonLabel>
                                                    <IonSelect value={classID}
                                                            className="input-field"
                                                            placeholder={t("select_class")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => setClassi(e.detail.value)
                                                        }>

                                                            { classes.map((classy) =>
                                                            (classy.isActive) &&
                                                             (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                            )}
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("select_slots")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className='ion-margin-vertical'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {timeSlots && timeSlots.length > 1 && timeSlots.map((slot, i) => (
                                            (slot.isActive) &&
                                            (<IonItem key={slot._id}
                                                detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonCheckbox slot="start" value={slot._id} checked={slot.isChecked} 
                                                    onIonChange={e => handleChecked(e.detail.checked, i)}/>
                                                <IonLabel>{slot.name} ({slot.startTime} - {slot.endTime})</IonLabel>
                                            </IonItem>)
                                        ))}

                                    </IonList>
                                    </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("assign_subjects")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddTimeTableOne;
