import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    employeeID: string
}

interface Department {
    _id: string,
    name: string
}

const BasicInfo: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {employeeID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [userID, setUserID] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [gender, setGender] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [designation, setDesignation] = useState < string > ("");
    const [qualification, setQualification] = useState < string > ("");
    const [joiningDate, setJoiningDate] = useState < string | string[] | undefined > ();
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [bankName, setBankName] = useState < string > ("");
    const [branchName, setBranchName] = useState < string > ("");
    const [accountNo, setAccountNo] = useState < string > ("");
    const [ifscCode, setIfscCode] = useState < string > ("");
    const [enrollNo, setEnrollNo] = useState < string > ("");

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleSection = (section: string) => {

        const toggleEmployee = (isActive: string) => {
            return new Promise((resolve, reject) => {
    
                api.put(`/employees/${employeeID}`, { 'isActive': section }).then(res => {
                    if((userID !== "") && (userID !== "none"))
                    {
                        api.put('/users/'+userID, { 'isActive': section }).then(resu => {
                            return resolve(resu.data.name);
                        }).catch(err => reject(err));   
                    }
                    else
                    {
                        return resolve(res.data.userInfo.name);
                    }
                    
                    
                  }).catch(err => reject(err));
    
            });
        }

        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);
            toggleEmployee(section)
            .then(data => {

                setActive(section);
                setPass(data+t("has_been_updated"));
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
    }

    const handleSubmit = async () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage(t("address_mandatory"));
            setIserror(true);
            return;
        }

        if (!gender || gender === "") {
            setMessage(t("gender_mandatory"));
            setIserror(true);
            return;
        }

        if (!department || department === "") {
            setMessage(t("department_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof selectedDate !== "string")
        {
            setMessage(t("dob_mandatory"));
            setIserror(true);
            return;
        }

        const departmentName = departments.find(val => val._id === department)!.name;

        let joining = "";
        if (typeof joiningDate === "string")
        {
           joining = joiningDate;
        }

        setShowLoading(true);
  
        const upEmployee = (dob: string, departmentID: string) => {
            return new Promise((resolve, reject) => {
              api.put('/employees/'+employeeID, { userID, name, departmentID, departmentName, gender, address, dob, enrollNo,
              joining, designation, qualification, accountNo, bankName, branchName, ifscCode }).then(res => {
        
                if((userID !== "") && (userID !== "none"))
                    {
                        api.put('/users/'+userID, { name }).then(resu => {
                            return resolve(resu.data.name);
                        }).catch(err => reject(err));   
                    }
                    else
                    {
                        return resolve(res.data.userInfo.name);
                    }
                
              }).catch(err => reject(err));
    
            });
        }
      
        upEmployee(selectedDate, department)
        .then(data => {
    
            setPass(data+t("has_been_updated"));
            setIspass(true);
            
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
            
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/employees/' + employeeID);
                setUserID(result.data.userInfo.userID);

                setName(result.data.userInfo.name);
              
                setAddress(result.data.address);
                setSelectedDate(result.data.dob);
                setGender(result.data.gender);
                setEnrollNo(result.data.enrollNo);
                setDesignation(result.data.designation);
                setJoiningDate(result.data.joining);
                setQualification(result.data.qualification);

                if (result.data.bankInfo)
                {
                    setAccountNo(result.data.bankInfo.accountNo);
                    setIfscCode(result.data.bankInfo.ifscCode);
                    setBankName(result.data.bankInfo.bankName);
                    setBranchName(result.data.bankInfo.branchName);
                }

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                const resu = await api.get(`/departments/getAllInstitute/${result.data.instituteID}`);
                setDepartments(resu.data);

                setDepartment(result.data.departmentInfo.departmentID);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, employeeID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/employee/${employeeID}`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("basic_info")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("basic_info")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="mb-60 max-xl">
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("employee_details")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-10">
                                <IonCol>
                                    <IonCard className='border-radius-10 active-group'>
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        
                                                        {t("current_status")}
                                                    
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                                    {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                                    ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-padding-top ion-padding-horizontal">
                                                    <IonCol>
                                                        <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                            <IonSegmentButton value="enable">
                                                                <IonLabel>{t("make_active")}</IonLabel>
                                                            </IonSegmentButton>
                                                            <IonSegmentButton value="disable">
                                                                <IonLabel>{t("make_inactive")}</IonLabel>
                                                            </IonSegmentButton>
                                                        </IonSegment>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_name")}
                                                        value={name}
                                                        onIonInput={
                                                            (e) => setName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("employee_gender")}*
                                                    </IonLabel>
                                                    <IonSelect value={gender}
                                                        className="input-field"
                                                        placeholder={t("employee_gender")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setGender(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                        <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                        <IonSelectOption value="other">{t("other")}</IonSelectOption> 
                                                        
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("employee_dob")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("employee_dob")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_address")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("employee_address")}
                                                        value={address}
                                                        onIonInput={
                                                            (e) => setAddress(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_dep")}*
                                                </IonLabel>
                                                    <IonSelect value={department}
                                                            className="input-field"
                                                            placeholder={t("employee_dep")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => setDepartment(e.detail.value)
                                                        }>

                                                            {departments.length > 0 && departments.map((dep) => (
                                                                        (<IonSelectOption key={dep._id} value={dep._id}>{dep.name}</IonSelectOption>)
                                                                    ))}

                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("enrollment_no")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("enrollment_no")}
                                                        value={enrollNo}
                                                        onIonInput={
                                                            (e) => setEnrollNo(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("employment_info")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("designation")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("designation")}
                                                        value={designation}
                                                        onIonInput={
                                                            (e) => setDesignation(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-join-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("joining_date")}
                                                    </IonLabel>
                                                    <IonInput value={formatDate(joiningDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-join-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("joining_date")}</IonTitle>
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={joiningDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setJoiningDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("qualification")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("qualification")}
                                                        value={qualification}
                                                        onIonInput={
                                                            (e) => setQualification(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("bank_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("account_no")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("account_no")}
                                                        value={accountNo}
                                                        onIonInput={
                                                            (e) => setAccountNo(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("ifsc_code")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("ifsc_code")}
                                                        value={ifscCode}
                                                        onIonInput={
                                                            (e) => setIfscCode(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("bank_name")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("bank_name")}
                                                        value={bankName}
                                                        onIonInput={
                                                            (e) => setBankName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("branch_name")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("branch_name")}
                                                        value={branchName}
                                                        onIonInput={
                                                            (e) => setBranchName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_employee")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default BasicInfo;
