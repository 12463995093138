import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { addCircleOutline, alertCircleOutline, eye, informationCircleOutline, pencil, trash, close, camera } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import SwitchStudent from '../../components/SwitchStudent';
import useApiService from '../../components/ApiService';

import '../../theme/icard.css';

interface Guradian {
    name: string,
    relation: string,
    photo: string,
    phone?: string
}

interface Student {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    parentInfo?: ParentInfo,
    photo?: string,
    isActive: boolean
}

interface ClassInfo {
    className: string,
    session: string
}

interface ParentInfo {
    name: string,
    phone: string,
    email: string
}

const KTWSinstitutes = ["623b78f1bbe7171e30738c7a", "623b7220bbe7171e30738a7c", "623b7808bbe7171e30738c74", "623b784fbbe7171e30738c77"];

const StudentGuardians: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [guardians, setGuardians] = useState < Guradian[] > ([]);
    const [selected, setSelected] = useState < number > ();
    const [name, setName] = useState < string > ("");
    const [relation, setRelation] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showPopover, setShowPopover] = useState < boolean > (false);
    const [showPopover1, setShowPopover1] = useState < boolean > (false);
    const [name1, setName1] = useState < string > ("");
    const [relation1, setRelation1] = useState < string > ("");
    const [phone1, setPhone1] = useState < string > ("");
    const [studentID, setStudentID] = useState < string > ("");
    const [showModal, setShowModal] = useState < boolean > (false);
    const [student, setStudent] = useState < Student > ();

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    };

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };
      
    const pickImage = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const handleAdd = () => {

        if (guardians.length === 4) {
            setMessage(t("max_guardians"));
            setIserror(true);
            return;
        }

        if (!name || name === "") {
            setMessage(t("guardian_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!relation || relation === "") {
            setMessage(t("guardian_relation_mandatory"));
            setIserror(true);
            return;
        }

        const upStudent = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/students/addGuardian', formData).then(res => {
                return resolve(res.data.student.name);
              }).catch(err => reject(err));
            });
        }

        const updateStudent = () => {
            return new Promise((resolve, reject) => {
              api.post('/students/newGuardian', {studentID, name, relation, phone}).then(res => {
                return resolve(res.data.student.name);
              }).catch(err => reject(err));
            });
        }

        if (uploadFile) {
            let formData = new FormData();
            formData.append('guardian', uploadFile, 'guardian');
            formData.append('studentID', studentID);
            formData.append('name', name);
            formData.append('relation', relation);
            formData.append('phone', phone);
    
            setShowLoading(true);
            upStudent(formData)
            .then(data => {
               setShowPopover(false);
                setPass(data+t("has_been_updated"));
                setIspass(true);
            })
            .catch((error) => {
               //
            })
            .finally(() => setShowLoading(false));
        } else {
            setShowLoading(true);
            updateStudent()
            .then(data => {
               setShowPopover(false);
                setPass(data+t("has_been_updated"));
                setIspass(true);
            })
            .catch((error) => {
               //
            })
            .finally(() => setShowLoading(false));
        }
        
    }

    const handleDelete = () => {
  
        const deleteGuardian = () => {
            return new Promise((resolve, reject) => {
                api.get(`/students/removeGuardian/${studentID}/${selected}`).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        }

        setShowLoading(true);
        deleteGuardian()
        .then(() => {

            setPass(t("guardian_removed"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSubmit = () => {

        if (!name1 || name1 === "") {
            setMessage(t("guardian_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!relation1 || relation1 === "") {
            setMessage(t("guardian_relation_mandatory"));
            setIserror(true);
            return;
        }

        const updateApp = (name: string, relation: string, phone: string) => {
          return new Promise((resolve, reject) => {
            api.post(`/students/updateGuardian/${studentID}/${selected}`, {name, relation, phone}).then(res => {
                  return resolve(res.data.student.name);
            }).catch(err => reject(err));
          });
        }

        setShowLoading(true);
        updateApp(name1, relation1, phone1)
        .then((data) => {

            setShowPopover1(false);
            setPass(data+t("has_been_updated"));
            setIspass(true);
        })
        .catch((error) => {
           //
        })
        .finally(() => setShowLoading(false));
    }
  
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudentID(result.data._id);
                setStudent(result.data);
                if (result.data.guardians)
                {
                    setGuardians(result.data.guardians);
                }

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/user/profile/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("guardian_info")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("guardian_info")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_removal")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />

               <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonModal isOpen={showModal}
                    onDidDismiss={
                        () => setShowModal(false)
                }>

                    <IonContent className="grey-shade">
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton onClick={
                                        () => setShowModal(false)
                                    }>
                                        <IonIcon icon={close} size="large" />
                                    </IonButton>
                                </IonButtons>
                                <IonTitle color="secondary">{t("guardian_card")}</IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonCard>
                            <IonCardContent>
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                    <div id="container">
                                        <div id="idtop">
                                            <div id="toplines">{authInfo.institute.name}</div>
                                            {authInfo.institute.address} | {authInfo.institute.phone}	
                                        </div>
                                        
                                        <div id='sphoto' style={{ background: "url(assets/images/guardianBack.png) no-repeat left center" }}>
                                            
                                            <div id="sname" className="text-uppercase">{t("guardian_card")}</div> 
                                                <span className="helper"></span>
                                                {guardians[0] && guardians[0].photo ?
                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${guardians[0].photo}`} alt={guardians[0].name} id="mimg" />)
                                                    : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} id="mimg" />)
                                                }
                                                {student && student.photo ?
                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.photo}`} alt={student.name} id="simg" />)
                                                    : (<img src={`assets/images/avatar.png`} alt={student?.name} id="simg" />)
                                                }
                                                {guardians[1] && guardians[1].photo ?
                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${guardians[1].photo}`} alt={guardians[1].name} id="mimg" />)
                                                    : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} id="mimg" />)
                                                }
                                        </div>
                                        <div id="names" className="text-capitalize">
                                            <div id="sname">{student?.name}</div>
                                        </div>
                                        <div id="classes">
                                            <div className="label">{t("class")}</div>
                                            <div className="label">{t("phone")}</div>
                                            <div className="label">{t("parent")}</div>
                                        </div>
                                        <div id="classes2">
                                            <div className="label">{student?.classInfo.className}</div>
                                            <div className="label">{student?.parentInfo?.phone}</div>
                                            <div className="label">{student?.parentInfo?.name}</div>
                                        </div>
                                        <div id="add">
                                            <span className="bold">&nbsp;</span>	
                                        </div>
                                        <div id="ph">
                                            <QRCode value={`https://prathmic.com/app/qr/student/${studentID}`} id="mimg" />
                                            {guardians[2] && guardians[2].photo ?
                                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${guardians[2].photo}`} alt={guardians[2].name} id="mimg" />)
                                                : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} id="mimg" />)
                                            }
                                            {guardians[3] && guardians[3].photo ?
                                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${guardians[3].photo}`} alt={guardians[3].name} id="mimg" />)
                                                : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} id="mimg" />)
                                            }
                                        </div>
                                    </div>
                                            
                                </div>
                            </div>
                            </IonCardContent>
                        </IonCard>
                       
                    </IonContent>
                </IonModal>

                <SwitchStudent refreshed={refreshed} setRefreshed={setRefreshed} />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {t("guardian_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'> 
                        
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    detail={true}
                                    onClick={() => setShowPopover(true)}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>{t("add_guardian")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            <IonPopover size='cover' isOpen={showPopover} onDidDismiss={e => setShowPopover(false)}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("name")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={name}
                                                        placeholder={t("name")}
                                                        onIonInput={
                                                            (e) => setName(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("relation")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={relation}
                                                        placeholder={t("relation")}
                                                        onIonInput={
                                                            (e) => setRelation(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("phone")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={phone}
                                                        placeholder={t("phone")}
                                                        onIonInput={
                                                            (e) => setPhone(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={pickImage}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                     {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(uploadFile) && (
                                                <IonItem detail={true}
                                                        color="danger"
                                                        detailIcon={trash}
                                                        lines='none'
                                                        button={true}
                                                        onClick={() => setUploadFile(null)}
                                                    >
                                                    <IonBadge color="light" slot="start">1</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton onClick={handleAdd}
                                            expand="block"
                                            color="secondary">{t("add_guardian")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                        </IonCol>

                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec'>
                                {t("all_guardians")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                          <IonList className="ion-no-padding">
                         
                             {(guardians.length > 0) ? (guardians.map((post, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={i}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            >
                                            
                                            <IonLabel className='list-label'>
                                                <h3>{post.name} </h3>
                                            </IonLabel>
                                            <IonButtons slot="end">
                                            {post.photo && (
                                                <IonButton onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/images/${post.photo}`, post.name)}>
                                                    <IonIcon 
                                                    icon={eye}
                                                    color="dark"
                                                    
                                                    />
                                                 </IonButton>
                                            )}
                                                 <IonButton onClick={() => {
                                                                        setSelected(i);
                                                                        setName1(post.name);
                                                                        setRelation1(post.relation);
                                                                        setPhone1(post.phone || "");
                                                                        setShowPopover1(true);
                                                                    }}>
                                                    <IonIcon 
                                                    icon={pencil}
                                                    color="primary"
                                                    
                                                    />
                                                 </IonButton>
                                                <IonButton onClick={() => {
                                                                        setSelected(i);
                                                                        setShowAlert(true);
                                                                    }}>
                                                    <IonIcon 
                                                    icon={trash}
                                                    color="danger"
                                                    
                                                    />
                                                 </IonButton>
                                            </IonButtons> 
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none" className='red-card'>
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_guardians")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } 
                            </IonList>
                            <IonPopover size='cover' isOpen={showPopover1} onDidDismiss={e => setShowPopover1(false)}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("name")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={name1}
                                                        placeholder={t("name")}
                                                        onIonInput={
                                                            (e) => setName1(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("relation")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={relation1}
                                                        placeholder={t("relation")}
                                                        onIonInput={
                                                            (e) => setRelation1(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("phone")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={phone1}
                                                        placeholder={t("phone")}
                                                        onIonInput={
                                                            (e) => setPhone1(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton onClick={handleSubmit}
                                            expand="block"
                                            color="secondary">{t("update_guardian")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            {(KTWSinstitutes.includes(authInfo.institute._id)) && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={ () => (guardians.length > 0) && setShowModal(true)}
                                className="first-button"
                                fill="clear">{t("view_guardian_card")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
             )}
        </IonPage>
    );
};

export default StudentGuardians;
