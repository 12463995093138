import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/PageUnloadHandler';
import useApiService from '../../../components/ApiService';

interface Student {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    dob: Date,
    gender?: string,
    due: number,
    isActive: boolean
}
interface ClassInfo {
    className: string,
    session: string
}

const GraduatedStudents: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [students, setStudents] = useState<Student[]>([]);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [refreshed, setRefreshed] = useState<number>(0);
    const [skip, setSkip] = useState<number>(0);
    const [current, setCurrent] = useState<string>("");
    const [total, setTotal] = useState<number>(0);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const scrollContent = useRef<HTMLIonContentElement | null>(null);
    const [initialRender, setInitialRender] = useState<boolean>(true);

    // Handle scroll end event to update scroll position
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    };

    // Refresh handler to reload data
    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    // Format date to 'en-GB' format
    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    // Load items from the API
    const loadItems = async () => {
        let limit = skip + 30;

        try {
            const result = await api.get(`/students/graduated/${authInfo.user.instituteID}?skip=${limit}`);
            if (result.data.students && result.data.students.length) {
                setStudents([...students, ...result.data.students]);
                setSkip(limit);
            }
        } catch (error: any) {
            //
        }
    };

    // Effect to handle data loading based on navigation and state restoration
    useEffect(() => {
        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            // Restore state from session storage on back navigation
            const { students, total, skip, scrollPosition } = JSON.parse(storedState);
            setStudents(students);
            setTotal(total);
            setSkip(skip);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } else {
            // Fetch data from API if no stored state or initial navigation
            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const result = await api.get(`/students/graduated/${authInfo.user.instituteID}`);
                    setStudents(result.data.students);
                    setTotal(result.data.total);
                    setSkip(0);
                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
            };
            fetchUp();
        }
        setInitialRender(false);
    }, [authInfo, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) {
            const currentState = {
                students,
                total,
                skip,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
      
    }, [students, total, skip, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("graduated_students")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("graduated_students")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">

                            <IonCol sizeLg="3" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                />
                                <IonText className='ion-margin-start'>{t("name")}</IonText>
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {(authInfo.institute.type === "school") ? t("class") : t("batch")}
                            </IonCol>
                            <IonCol sizeLg="2" className='table-heading'>
                            {t("dob")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("gender")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("dues")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>

                        {(students.length > 0) ? (students.map((student, i) => {
                            return (
                                <IonRow key={student._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                onClick={
                                    () => setCurrent(student._id)
                                }
                                onDoubleClick={
                                    () => history.push(`/app/student/${student._id}`)
                                }>
                                    
                                        <IonCol sizeLg="3" size="8" className='table-field' >
                                            <IonRow>
                                                <IonCol>
                                                    <IonRadio value={student._id} className='table-icon' color="secondary" />
                                                    <IonText className='ion-margin-start text-capitalize'>{student.name}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                <IonCol>
                                                    <IonLabel>
                                                        {student.classInfo.className} &#8226; {student.gender}
                                                    </IonLabel>
                                                        
                                                </IonCol>
                                            </IonRow>
                                            
                                        </IonCol>
                                        <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            {student.classInfo.className}
                                        </IonCol>
                                        <IonCol sizeLg="2" size="3" className='table-field'>
                                            {formatDated(student.dob)}
                                        </IonCol>
                                        <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            {student.gender}
                                        </IonCol>
                                        <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            {student.due}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/student/${student._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                            {(students.length > 0) &&
                             <IonRow>
                                <IonCol>
                                    <IonInfiniteScroll
                                        onIonInfinite={async (ev) => {
                                            await loadItems();
                                            ev.target.complete();
                                        }}
                                        >
                                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                    </IonInfiniteScroll>
                                </IonCol>
                            </IonRow>
                            }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${students.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_student"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/student/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("manage_student")}</IonButton>

                                    <IonButton 
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("export_excel")}</IonButton>


                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default GraduatedStudents;